// // src/components/ProductModal.js

// import React from 'react';
// import { Modal, Box, Typography, Button, IconButton } from '@mui/material';
// import { Link } from 'react-router-dom';
// import CloseIcon from '@mui/icons-material/Close';
// import './ProductModal.css'; // Import the CSS file for the modal

// const ProductModal = ({ product, handleClose }) => {
//   if (!product) {
//     return null;
//   }

//   return (
//     <Modal
//       open={!!product}
//       onClose={handleClose}
//       aria-labelledby="product-modal-title"
//       aria-describedby="product-modal-description"
//     >
//       <Box className="modal-box">
//         {/* <IconButton className="close-button" onClick={handleClose}>
//           <CloseIcon />
//         </IconButton> */}
//         <Typography id="product-modal-title" variant="h6" component="h2">
//           {product.name}
//         </Typography>
//         <Box className="modal-content">
//           <Box className="modal-image">
//             <img src={product.image} alt={product.brandName} />
//           </Box>
//           <Box className="modal-details">
//             <Typography variant="subtitle1" component="p">
//               <strong>Brand:</strong> {product.brandName}
//             </Typography>
//             <Typography variant="subtitle1" component="p">
//               <strong>Generic Name:</strong> {product.genericName}
//             </Typography>
//             <Typography variant="body2" component="p" className="modal-description">
//               {product.description}
//             </Typography>
//             <Link to={`/product/${product.id}`} className="more-info-link">
//               <Button variant="outlined" color="primary">
//                 More Info
//               </Button>
//             </Link>
//           </Box>
//         </Box>
//       </Box>
//     </Modal>
//   );
// };

// export default ProductModal;

// import React, {useState} from "react";
// import {
//   Modal,
//   Box,
//   Container,
//   Grid,
//   Typography,
//   Paper,
//   Button,
//   IconButton,
//   Dialog,
//   DialogTitle,
//   DialogContent,
// } from "@mui/material";

// import { Link } from "react-router-dom";
// import CloseIcon from "@mui/icons-material/Close";
// import "./ProductModal.css"; // Import the CSS file for the modal
// import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
// import { Viewer, Worker } from '@react-pdf-viewer/core';
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// import { Visibility as VisibilityIcon } from '@mui/icons-material';


// import '@react-pdf-viewer/core/lib/styles/index.css';
// import '@react-pdf-viewer/default-layout/lib/styles/index.css';

// const ProductModal = ({ product, handleClose }) => {
//   const navigate = useNavigate(); // Hook for navigation
//   const [selectedPdf, setSelectedPdf] = useState(null);
//   const defaultLayoutPluginInstance = defaultLayoutPlugin();
//   const [open, setOpen] = useState(false);

//   console.log(JSON.stringify(product) + " From modal view");
//   if (!product) {
//     return null;
//   }

//   // PDF open handler
//   const handlePdfOpen = (pdf) => {
//     setSelectedPdf(pdf);
//     setOpen(true);
//   };
//   // PDF close handler

//   const handlePdfClose = () => {
//     setOpen(false);
//     setSelectedPdf(null);
//   };


//   const handleMoreInfoClick = () => {
//     navigate(`/product/${product.id}`); // Navigate to product detail page using useNavigate
//   };

//   return (
//     <Modal
//       open={!!product}
//       onClose={handleClose}
//       aria-labelledby="product-modal-title"
//       aria-describedby="product-modal-description"
//       className="product-modal"
//     >
//       <Box className="modal-box">
//         <IconButton className="close-button" onClick={handleClose}>
//           <CloseIcon />
//         </IconButton>
//         <Typography
//           id="product-modal-title"
//           variant="h4"
//           component="h2"
//           className="modal-title"
//         >
//           {product.brandName}
//         </Typography>
//         <Box className="modal-content">
//           {/* <Box className="modal-image">
//             <img
//               src={product.image}
//               alt={product.brandName}
//               className="responsive-image"
//             />
//           </Box> */}
//           <Box className="modal-details">
//             <Typography variant="subtitle1" component="p">
//               <strong>Brand:</strong> {product.brandName}
//             </Typography>
//             <Typography variant="subtitle1" component="p">
//               <strong>Generic Name:</strong> {product.genericName}
//             </Typography>
//             <Typography
//               variant="body2"
//               component="p"
//               className="modal-description"
//             >
//               {product.description}
//             </Typography>
//             <Button
//               onClick={handleMoreInfoClick}
//               variant="contained"
//               color="primary"
//             >
//               More Info
//             </Button>
//           </Box>
//         </Box>
//       </Box>
//       {/* <Box className="product-pdfs" mt={5}>
//           <Grid container spacing={3}>
//             {product.pdfs?.map((pdf, index) => (
//               <Grid item xs={12} key={index}>
//                 <Box className="pdf-viewer" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
//                   <Button
//                     variant="outlined"
//                     startIcon={<VisibilityIcon />}
//                     onClick={() => handlePdfOpen(pdf)}
//                     color="primary"
//                   >
//                     Show details
//                   </Button>
//                 </Box>
//               </Grid>
//             ))}
//           </Grid>
//         </Box> */}
//       {/* <Dialog open={open} onClose={handlePdfClose} maxWidth="md" fullWidth>
//         <DialogTitle>{product.brandName}</DialogTitle>
//         <DialogContent>
//           {selectedPdf && (
//             <Box sx={{ height: '80vh' }}>
//               <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}>
//                 <Viewer fileUrl={selectedPdf} plugins={[defaultLayoutPluginInstance]} />
//               </Worker>
//             </Box>
//           )}
//         </DialogContent>
//       </Dialog> */}
//     </Modal>
//   );
// };

// export default ProductModal;



// import React, { useState } from "react";
// import {
//   Modal,
//   Box,
//   Typography,
//   Button,
//   IconButton,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   Grid,
//   Tabs, Tab
// } from "@mui/material";
// import { useNavigate } from "react-router-dom";
// import CloseIcon from "@mui/icons-material/Close";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import "./ProductModal.css";
// import { Viewer, Worker } from '@react-pdf-viewer/core';
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// import '@react-pdf-viewer/core/lib/styles/index.css';
// import '@react-pdf-viewer/default-layout/lib/styles/index.css';

// import { HOST_URL } from "../../config/Url";

// const ProductModal = ({ product, handleClose }) => {
//   const navigate = useNavigate();
//   const [selectedPdf, setSelectedPdf] = useState(null);
//   const defaultLayoutPluginInstance = defaultLayoutPlugin();
//   const [pdfDialogOpen, setPdfDialogOpen] = useState(false);
//   const [selectedTabIndex, setSelectedTabIndex] = useState(0);
//   const [open, setOpen] = useState(false);

//   if (!product) {
//     return null;
//   }

//   const handlePdfOpen = (pdf) => {
//     setSelectedPdf(pdf);
//     setPdfDialogOpen(true);
//   };

//   const handlePdfClose = () => {
//     setPdfDialogOpen(false);
//     setSelectedPdf(null);
//   };

//   const handleMoreInfoClick = () => {
//     navigate(`/product/${product.id}`);
//   };

//   const handleTabChange = (event, newValue) => {
//     setSelectedTabIndex(newValue);
//   };

//   const sliderSettings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 2000,
//   };

//   const imageFiles = product.fileResources?.filter(file => 
//     ['png', 'jpg', 'jpeg'].includes(file.fileType?.toLowerCase())
//   ) || [];

//   const pdfFiles = product.fileResources?.filter(file => 
//     ['pdf'].includes(file.fileType?.toLowerCase())
//   ) || [];

//   console.log(pdfFiles)

//   const getImageUrl = (filePath) => {
//     if (!filePath) return '';
//     return `${HOST_URL}/${filePath}`;
//   };

//   return (
//     <Modal
//       open={!!product}
//       onClose={handleClose}
//       aria-labelledby="product-modal-title"
//       aria-describedby="product-modal-description"
//       className="product-modal"
//     >
//       <Box className="modal-box" sx={{ 
//         width: '90%', 
//         maxWidth: '800px', 
//         maxHeight: '90vh', 
//         overflow: 'auto', 
//         bgcolor: 'background.paper',
//         boxShadow: 24,
//         p: 4,
//         borderRadius: 2,
//       }}>
//         <IconButton 
//           className="close-button" 
//           onClick={handleClose}
//           sx={{ position: 'absolute', right: 8, top: 8 }}
//         >
//           <CloseIcon />
//         </IconButton>
//         <Typography
//           id="product-modal-title"
//           variant="h4"
//           component="h2"
//           className="modal-title"
//           sx={{ mb: 2 }}
//         >
//           {product.brandName}
//         </Typography>
//         <Box className="modal-content">
//         <Box sx={{ width: '100%', mb: 2 }}>
//           {imageFiles.length > 0 ? (
//             <Slider {...sliderSettings}>
//               {imageFiles.map((image, index) => (
//                 <div key={index}>
//                   <img
//                     src={`${HOST_URL}/${image.filePath}`}
//                     alt={`${product.brandName} - ${index + 1}`}
//                     style={{ 
//                       width: '100%', 
//                       height: '300px', 
//                       objectFit: 'contain',
//                       backgroundColor: '#f0f0f0' 
//                     }}
//                     // onError={(e) => {
//                     //   console.error("Image failed to load:", e.target.src);
//                     //   e.target.src = 'path/to/fallback/image.jpg'; // Replace with a fallback image path
//                     // }}
//                   />
//                 </div>
//               ))}
//             </Slider>
//           ) : (
//             <Box sx={{ 
//               width: '100%', 
//               height: '300px', 
//               display: 'flex', 
//               justifyContent: 'center', 
//               alignItems: 'center',
//               backgroundColor: '#f0f0f0'
//             }}>
//               <Typography>No images available</Typography>
//             </Box>
//           )}
//         </Box>
//           <Box className="modal-details">
//             <Typography variant="subtitle1" component="p">
//               <strong>Brand:</strong> {product.brandName}
//             </Typography>
//             <Typography variant="subtitle1" component="p">
//               <strong>Generic Name:</strong> {product.genericName}
//             </Typography>
//             <Typography
//               variant="body2"
//               component="p"
//               className="modal-description"
//               sx={{ mt: 2 }}
//             >
//               {product.description}
//             </Typography>
//             {/* <Button
//               onClick={handleMoreInfoClick}
//               variant="contained"
//               color="primary"
//               sx={{ mt: 2 }}
//             >
//               More Info
//             </Button> */}
//           </Box>
//         </Box>
      //   <Box className="product-pdfs" sx={{ mt: 3 }}>
      //     <Grid container spacing={2}>
      //       {pdfFiles.map((pdf, index) => (
      //         <Grid item xs={12} sm={6} md={4} key={index}>
      //           <Button
      //             variant="outlined"
      //             startIcon={<VisibilityIcon />}
      //             onClick={() => handlePdfOpen(getImageUrl(pdf.filePath))}
      //             color="primary"
      //             fullWidth
      //           >
      //             Show PDF {index + 1}
      //           </Button>
      //         </Grid>
      //       ))}
      //     </Grid>
      //   </Box>
      // </Box>
      //  <Dialog open={open} onClose={handlePdfClose} maxWidth="md" fullWidth>
      //    <DialogTitle>{product.brandName}</DialogTitle>
      //   <DialogContent>
      //     {selectedPdf && (
      //       <Box sx={{ height: '80vh' }}>
      //         <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}>
      //           <Viewer fileUrl={selectedPdf} plugins={[defaultLayoutPluginInstance]} />
      //         </Worker>
      //       </Box>
      //     )}
      //   </DialogContent>
      // </Dialog> 
//     </Modal>
//   );
// };

// export default ProductModal;


// import React, { useState } from "react";
// import {
//   Modal,
//   Box,
//   Typography,
//   IconButton,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   Grid,
//   Button,
//   Tabs,
//   Tab
// } from "@mui/material";
// import { useNavigate } from "react-router-dom";
// import CloseIcon from "@mui/icons-material/Close";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import "./ProductModal.css";
// import { Viewer, Worker } from '@react-pdf-viewer/core';
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import '@react-pdf-viewer/core/lib/styles/index.css';
// import '@react-pdf-viewer/default-layout/lib/styles/index.css';
// import { HOST_URL } from "../../config/Url";

// const ProductModal = ({ product, handleClose }) => {
//   const navigate = useNavigate();
//   const defaultLayoutPluginInstance = defaultLayoutPlugin();
//   const [selectedPdf, setSelectedPdf] = useState(null);
//   const [pdfDialogOpen, setPdfDialogOpen] = useState(false);

//   if (!product) {
//     return null;
//   }

//   const handlePdfOpen = (pdf) => {
//     setSelectedPdf(pdf);
//     setPdfDialogOpen(true);
//   };

//   const handlePdfClose = () => {
//     setPdfDialogOpen(false);
//     setSelectedPdf(null);
//   };

//   const handleMoreInfoClick = () => {
//     navigate(`/product/${product.id}`);
//   };

//   const sliderSettings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 2000,
//   };

//   const imageFiles = product.fileResources?.filter(file => 
//     ['png', 'jpg', 'jpeg'].includes(file.fileType?.toLowerCase())
//   ) || [];

//   let pdfFiles = product.fileResources?.filter(file => 
//     ['pdf'].includes(file.fileType?.toLowerCase())
//   ) || [];

//   pdfFiles = pdfFiles.length > 0 ? [pdfFiles[pdfFiles.length - 1]] : [];

//   console.log(pdfFiles)

//   const getImageUrl = (filePath) => {
//     if (!filePath) return '';
//     return `${HOST_URL}/${filePath}`;
//   };

//   return (
//     <Modal
//       open={!!product}
//       onClose={handleClose}
//       aria-labelledby="product-modal-title"
//       aria-describedby="product-modal-description"
//       className="product-modal"
//     >
//       <Box className="modal-box" sx={{ 
//         width: '90%', 
//         maxWidth: '800px', 
//         maxHeight: '90vh', 
//         overflow: 'auto', 
//         bgcolor: 'background.paper',
//         boxShadow: 24,
//         p: 4,
//         borderRadius: 2,
//       }}>
//         <IconButton 
//           className="close-button" 
//           onClick={handleClose}
//           sx={{ position: 'absolute', right: 8, top: 8 }}
//         >
//           <CloseIcon />
//         </IconButton>
//         <Typography
//           id="product-modal-title"
//           variant="h4"
//           component="h2"
//           className="modal-title"
//           sx={{ mb: 2 }}
//         >
//           {product.brandName}
//         </Typography>
//         <Box className="modal-content">
//         <Box sx={{ width: '100%', mb: 2 }}>
//           {imageFiles.length > 0 ? (
//             <Slider {...sliderSettings}>
//               {imageFiles.map((image, index) => (
//                 <div key={index}>
//                   <img
//                     src={`${HOST_URL}/${image.filePath}`}
//                     alt={`${product.brandName} - ${index + 1}`}
//                     style={{ 
//                       width: '100%', 
//                       height: '300px', 
//                       objectFit: 'contain',
//                       backgroundColor: '#f0f0f0' 
//                     }}
//                   />
//                 </div>
//               ))}
//             </Slider>
//           ) : (
//             <Box sx={{ 
//               width: '100%', 
//               height: '300px', 
//               display: 'flex', 
//               justifyContent: 'center', 
//               alignItems: 'center',
//               backgroundColor: '#f0f0f0'
//             }}>
//               <Typography>No images available</Typography>
//             </Box>
//           )}
//         </Box>
//           <Box className="modal-details">
//             <Typography variant="subtitle1" component="p">
//               <strong>Brand:</strong> {product.brandName}
//             </Typography>
//             <Typography variant="subtitle1" component="p">
//               <strong>Generic Name:</strong> {product.genericName}
//             </Typography>
//             <Typography
//               variant="body2"
//               component="p"
//               className="modal-description"
//               sx={{ mt: 2 }}
//             >
//               {product.description}
//             </Typography>
//           </Box>
//         </Box>
//         <Box className="product-pdfs" sx={{ mt: 3 }}>
//           <Grid container spacing={2}>
//             {pdfFiles.map((pdf, index) => (
//               <Grid item xs={12} sm={6} md={4} key={index}>
//                 <Button
//                   variant="outlined"
//                   startIcon={<VisibilityIcon />}
//                   onClick={() => handlePdfOpen(`${HOST_URL}/${pdf.filePath}`)}
//                   color="primary"
//                   fullWidth
//                 >
//                   See Details {index + 1}
//                 </Button>
//               </Grid>
//             ))}
//           </Grid>
//         </Box>
//       </Box>
//       <Dialog open={pdfDialogOpen} onClose={handlePdfClose} maxWidth="md" fullWidth>
//         <DialogTitle>{product.brandName}</DialogTitle>
//         <DialogContent>
//           {selectedPdf && (
//             <Box sx={{ height: '80vh' }}>
//               <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}>
//                 <Viewer fileUrl={`${HOST_URL}uploads\\1719520866731-E‑Passport Online Shamim.pdf`} plugins={[defaultLayoutPluginInstance]} />
//               </Worker>
//             </Box>
//           )}
//         </DialogContent>
//       </Dialog>
//     </Modal>
//   );
// };

// export default ProductModal;


import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "./ProductModal.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HOST_URL } from "../../config/Url";

const ProductModal = ({ product, handleClose }) => {
  const navigate = useNavigate();
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [pdfDialogOpen, setPdfDialogOpen] = useState(false);

  if (!product) {
    return null;
  }

  const handlePdfOpen = (pdf) => {
    setSelectedPdf(pdf);
    setPdfDialogOpen(true);
  };

  const handlePdfClose = () => {
    setPdfDialogOpen(false);
    setSelectedPdf(null);
  };

  const handleMoreInfoClick = () => {
    navigate(`/product/${product.id}`);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const imageFiles = product.fileResources?.filter(file => 
    ['png', 'jpg', 'jpeg'].includes(file.fileType?.toLowerCase())
  ) || [];

  let pdfFiles = product.fileResources?.filter(file => 
    ['pdf'].includes(file.fileType?.toLowerCase())
  ) || [];

  pdfFiles = pdfFiles.length > 0 ? [pdfFiles[pdfFiles.length - 1]] : [];

  return (
    <>
      <Modal
        open={!!product}
        onClose={handleClose}
        aria-labelledby="product-modal-title"
        aria-describedby="product-modal-description"
        className="product-modal"
      >
        <Box className="modal-box" sx={{ 
          width: '90%', 
          maxWidth: '800px', 
          maxHeight: '90vh', 
          overflow: 'auto', 
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}>
          <IconButton 
            className="close-button" 
            onClick={handleClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            id="product-modal-title"
            variant="h4"
            component="h2"
            className="modal-title"
            sx={{ mb: 2 }}
          >
            {product.brandName}
          </Typography>
          <Box className="modal-content">
            <Box sx={{ width: '100%', mb: 2 }}>
              {imageFiles.length > 0 ? (
                <Slider {...sliderSettings}>
                  {imageFiles.map((image, index) => (
                    <div key={index}>
                      <img
                        src={`${HOST_URL}/${image.filePath}`}
                        alt={`${product.brandName} - ${index + 1}`}
                        style={{ 
                          width: '100%', 
                          height: '300px', 
                          objectFit: 'contain',
                          backgroundColor: '#f0f0f0' 
                        }}
                      />
                    </div>
                  ))}
                </Slider>
              ) : (
                <Box sx={{ 
                  width: '100%', 
                  height: '300px', 
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center',
                  backgroundColor: '#f0f0f0'
                }}>
                  <Typography>No images available</Typography>
                </Box>
              )}
            </Box>
            <Box className="modal-details">
              <Typography variant="subtitle1" component="p">
                <strong>Brand:</strong> {product.brandName}
              </Typography>
              <Typography variant="subtitle1" component="p">
                <strong>Generic Name:</strong> {product.genericName}
              </Typography>
              <Typography
                variant="body2"
                component="p"
                className="modal-description"
                sx={{ mt: 2 }}
              >
                {product.description}
              </Typography>
            </Box>
          </Box>
          <Box className="product-pdfs" sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              {pdfFiles.map((pdf, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Button
                    variant="outlined"
                    startIcon={<VisibilityIcon />}
                    onClick={() => handlePdfOpen(`${HOST_URL}/${pdf.filePath}`)}
                    color="primary"
                    fullWidth
                  >
                    See Details
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Modal>

      <Dialog open={pdfDialogOpen} onClose={handlePdfClose} maxWidth="md" fullWidth>
        <DialogTitle>{product.brandName}</DialogTitle>
        <DialogContent>
          {selectedPdf && (
            <iframe
              src={`${selectedPdf}#toolbar=0`}
              width="100%"
              height="600px"
              title="PDF Viewer"
              frameBorder="0"
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ProductModal;
