// import React from 'react';
// import { Container, Typography, Grid, Stack, Box } from '@mui/material';
// import {Link} from 'react-router-dom'
// import moment from 'moment';

// const Footer = () => {
//   return (
//     <Box component="footer" sx={{ bgcolor: '#2e3b4e', color: 'white', py: 6 }}>
//       <Container maxWidth="lg">
//         <Grid container spacing={4}>
//           <Grid item xs={12} sm={4}>
//             <Typography variant="h6" gutterBottom>
//               Company
//             </Typography>
//             <Stack spacing={1}>
//               <Link to="#" underline="none" color="white">
//                 About Us
//               </Link>
//               <Link to="#" underline="none" color="inherit">
//                 Contact Us
//               </Link>
//               <Link to="/mission-vision" underline="none" color="inherit">
//                 Mission & Vision
//               </Link>
//               <Link to="/privacy-policy" underline="none" color="inherit">
//                 Privacy Policy
//               </Link>
//             </Stack>
//           </Grid>
//           <Grid item xs={12} sm={4}>
//             <Typography variant="h6" gutterBottom>
//               Latest News
//             </Typography>
//             <Stack spacing={1}>
//               <Link href="#" underline="none" color="inherit">
//                 Fish & Moat
//               </Link>
//               <Link href="#" underline="none" color="inherit">
//                 Soft Drink
//               </Link>
//               <Link href="#" underline="none" color="inherit">
//                 Milk & Dairy
//               </Link>
//               <Link href="#" underline="none" color="inherit">
//                 Beauty & Health
//               </Link>
//             </Stack>
//           </Grid>
//           <Grid item xs={12} sm={4}>
//             <Typography variant="h6" gutterBottom>
//               My Account
//             </Typography>
//             <Stack spacing={1}>
//               <Link href="#" underline="none" color="inherit">
//                 Dashboard
//               </Link>
//               <Link href="#" underline="none" color="inherit">
//                 My Orders
//               </Link>
//               <Link href="#" underline="none" color="inherit">
//                 Account Details
//               </Link>
//               <Link href="#" underline="none" color="inherit">
//                 Update Profile
//               </Link>
//             </Stack>
//           </Grid>
//         </Grid>
//         <Grid container spacing={3} sx={{ mt: 5 }}>
//           <Grid item xs={12} sm={12}>
//             <Typography textAlign="center" variant="body2" color="inherit">
//               &copy; {moment().format('YYYY')} SoftShore Technologies. All rights reserved.
//             </Typography>
//           </Grid>
//           {/* <Grid item xs={12} sm={6}>
//             <Stack direction="row" spacing={2} justifyContent={{ xs: 'center', sm: 'flex-end' }}>
//               <Link href="#" underline="none" color="inherit">
//                 <img src="https://i.imgur.com/pTlmSJv.png" alt="Paypal" height={25} />
//               </Link>
//               <Link href="#" underline="none" color="inherit">
//                 <img src="https://i.imgur.com/pCwCM8t.png" alt="Visa" height={25} />
//               </Link>
//               <Link href="#" underline="none" color="inherit">
//                 <img src="https://i.imgur.com/iOEJjNQ.png" alt="Mastercard" height={25} />
//               </Link>
//             </Stack>
//           </Grid> */}
//         </Grid>
//       </Container>
//     </Box>
//   );
// };

// export default Footer;


import React from 'react';
import { Container, Typography, Grid, Stack, Box, Link } from '@mui/material';
import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';
import { Facebook, Twitter, Instagram, LinkedIn } from '@mui/icons-material';

const Footer = () => {
  return (
    <Box component="footer" sx={{ bgcolor: '#2e3b4e', color: 'white', py: 6 }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              Company
            </Typography>
            <Stack spacing={1}>
              <Link component={RouterLink} to="/about-us" underline="none" sx={{ color: 'inherit', '&:hover': { color: '#11A193' } }}>
                About Us
              </Link>
              <Link component={RouterLink} to="/our-services" underline="none" sx={{ color: 'inherit', '&:hover': { color: '#11A193' } }}>
                Our Servicess
              </Link>
              <Link component={RouterLink} to="/contact-us" underline="none" sx={{ color: 'inherit', '&:hover': { color: '#11A193' } }}>
                Contact Us
              </Link>
              <Link component={RouterLink} to="/privacy-policy" underline="none" sx={{ color: 'inherit', '&:hover': { color: '#11A193' } }}>
                Privacy Policy
              </Link>
            </Stack>
          </Grid>
          {/* <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Latest News
            </Typography>
            <Stack spacing={1}>
              <Link href="#" underline="none" sx={{ color: 'inherit', '&:hover': { color: '#11A193' } }}>
                Fish & Moat
              </Link>
              <Link href="#" underline="none" sx={{ color: 'inherit', '&:hover': { color: '#11A193' } }}>
                Soft Drink
              </Link>
              <Link href="#" underline="none" sx={{ color: 'inherit', '&:hover': { color: '#11A193' } }}>
                Milk & Dairy
              </Link>
              <Link href="#" underline="none" sx={{ color: 'inherit', '&:hover': { color: '#11A193' } }}>
                Beauty & Health
              </Link>
            </Stack>
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              My Account
            </Typography>
            <Stack spacing={1}>
              <Link href="#" underline="none" sx={{ color: 'inherit', '&:hover': { color: '#11A193' } }}>
                Dashboard
              </Link>
              <Link href="#" underline="none" sx={{ color: 'inherit', '&:hover': { color: '#11A193' } }}>
                My Orders
              </Link>
              <Link href="#" underline="none" sx={{ color: 'inherit', '&:hover': { color: '#11A193' } }}>
                Account Details
              </Link>
              <Link href="#" underline="none" sx={{ color: 'inherit', '&:hover': { color: '#11A193' } }}>
                Update Profile
              </Link>
            </Stack>
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 5 }}>
          <Grid item xs={12} sm={6}>
            <Stack direction="row" spacing={2} justifyContent={{ xs: 'center', sm: 'flex-start' }}>
              <Link href="#" underline="none" sx={{ color: 'inherit', '&:hover': { color: '#11A193' } }}>
                <Facebook />
              </Link>
              <Link href="#" underline="none" sx={{ color: 'inherit', '&:hover': { color: '#11A193' } }}>
                <Twitter />
              </Link>
              <Link href="#" underline="none" sx={{ color: 'inherit', '&:hover': { color: '#11A193' } }}>
                <Instagram />
              </Link>
              <Link href="#" underline="none" sx={{ color: 'inherit', '&:hover': { color: '#11A193' } }}>
                <LinkedIn />
              </Link>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography textAlign="center" variant="body2" color="inherit">
              &copy; {moment().format('YYYY')} SoftShore Technologies. All rights reserved.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
