// src/components/CompanyHistory.js

import React from 'react';
import { Container, Typography, Box, Paper, Grid, useMediaQuery, useTheme } from '@mui/material';
import './CompanyHistory.css';

const CompanyHistory = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container maxWidth="lg" className="company-history">
      <Box textAlign="center" my={4}>
        <Typography variant="h4" display="block" gutterBottom>
          OUR HISTORY
        </Typography>
      </Box>
      <Grid container spacing={4} className="history-section">
        <Grid item xs={12}>
          <Paper className="history-card" elevation={3}>
            <Box className="history-content">
              <Typography variant="h6" className="section-title">
                A Beacon of Pharmaceutical Excellence
              </Typography>
              <Typography variant="body1" paragraph>
                SAR Pharma International, a beacon of pharmaceutical excellence, has a remarkable journey that began in 2010.
                Established with a mission to improve healthcare access and provide high-quality medicines, the company embarked on a path
                that would lead to global recognition and admiration.
              </Typography>
              <Typography variant="h6" className="section-title">
                A Humble Beginning
              </Typography>
              <Typography variant="body1" paragraph>
                In its early days, SAR Pharma primarily focused on sourcing and supplying a diverse range of medicines from Bangladesh. This
                laid the foundation for a company dedicated to quality, integrity, and patient care.
              </Typography>
              <Typography variant="h6" className="section-title">
                A Response to Global Crisis
              </Typography>
              <Typography variant="body1" paragraph>
                The world faced an unprecedented challenge with the outbreak of the COVID-19 pandemic. SAR Pharma International swiftly
                pivoted to meet the urgent global demand for emergency medicines. With unwavering dedication, we supplied life-saving
                medications to various corners of the world, contributing to the global effort to combat the pandemic.
              </Typography>
              <Typography variant="h6" className="section-title">
                A Commitment to Oncology
              </Typography>
              <Typography variant="body1" paragraph>
                Today, SAR Pharma International is deeply committed to the field of oncology medicine. We proudly source and supply a broad
                spectrum of oncology medications, both from Bangladesh and overseas sources. Our mission is to ensure that patients worldwide
                have access to the critical medications they need to combat cancer and improve their quality of life.
              </Typography>
              <Typography variant="h6" className="section-title">
                Our Ongoing Journey
              </Typography>
              <Typography variant="body1" paragraph>
                As we continue our journey, our focus remains steadfast: to enhance healthcare, support patients in their battle against
                disease, and contribute to the well-being of communities around the globe. We invite you to explore our range of oncology
                medicines and experience the commitment and dedication that defines SAR Pharma International.
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CompanyHistory;
