// import React, { useContext, useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import { StoreContext } from '../../context/Store';

// const ProductDetail = () => {
//   const { id } = useParams();
//   const { dispatch } = useContext(StoreContext);
//   const [product, setProduct] = useState(null);

//   useEffect(() => {
//     const fetchProduct = async () => {
//       const { data } = await axios.get(`/api/products/${id}`);
//       setProduct(data);
//     };
//     fetchProduct();
//   }, [id]);

//   if (!product) return <p>Loading...</p>;

//   return (
//     <div>
//       <h1>{product.name}</h1>
//       <p>{product.description}</p>
//       <p>{product.price}</p>
//       <button onClick={() => dispatch({ type: 'ADD_TO_CART', payload: product })}>
//         Add to Cart
//       </button>
//     </div>
//   );
// };

// export default ProductDetail;




// ProductDetails.js
// import React from 'react';
// import { useMediaQuery } from 'react-responsive';
// import { Viewer, Worker } from '@react-pdf-viewer/core';
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';


// import '@react-pdf-viewer/core/lib/styles/index.css';
// import '@react-pdf-viewer/default-layout/lib/styles/index.css';

// import './ProductDetail.css';

// const ProductDetail = ({ product }) => {

  
//   const isMobile = useMediaQuery({ maxWidth: 767 });
//   const defaultLayoutPluginInstance = defaultLayoutPlugin();

//   return (
//     <div className="product-details">
//       <div className="product-header">
//         <h1 className="product-title">{product.title}</h1>
//         <p className="product-description">{product.description}</p>
//       </div>
//       <div className="product-images">
//         {product.images.map((image, index) => (
//           <img key={index} src={image} alt={`Product ${index}`} className="product-image" />
//         ))}
//       </div>
//       <div className="product-pdfs">
//         <h2>Attached PDF Documents</h2>
//         {product.pdfs.map((pdf, index) => (
//           <div key={index} className="pdf-viewer">
//             <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}>
//               <Viewer fileUrl={pdf} plugins={[defaultLayoutPluginInstance]} />
//             </Worker>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default ProductDetail;



  // import React, { useState } from 'react';
  // import { Container, Grid, Typography, Box, useMediaQuery, useTheme, Paper, IconButton, Dialog, DialogTitle, DialogContent, Button  } from '@mui/material';
  // import { Viewer, Worker } from '@react-pdf-viewer/core';
  // import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
  // import { PictureAsPdf as PdfIcon } from '@mui/icons-material';
  // import { Visibility as VisibilityIcon } from '@mui/icons-material';


  // import '@react-pdf-viewer/core/lib/styles/index.css';
  // import '@react-pdf-viewer/default-layout/lib/styles/index.css';

  // const ProductDetail = ({ product }) => {
  //   console.log("From Product Deataila " + JSON.stringify(product))
  //   const theme = useTheme();
  //   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  //   const defaultLayoutPluginInstance = defaultLayoutPlugin();

  //   const [open, setOpen] = useState(false);
  //   const [selectedPdf, setSelectedPdf] = useState(null);

  //   const handleOpen = (pdf) => {
  //     setSelectedPdf(pdf);
  //     setOpen(true);
  //   };

  //   const handleClose = () => {
  //     setOpen(false);
  //     setSelectedPdf(null);
  //   };

  //   return (
  //     <Container sx={{ py: 4 }}>
  //       <Paper elevation={3} sx={{ p: 3 }}>
  //         <Box className="product-header" mb={3}>
  //           <Typography variant={isMobile ? "h4" : "h3"} component="h1" color="primary" gutterBottom>
  //             {product.title}
  //           </Typography>
  //           <Typography variant="body1" color="textSecondary">
  //             {product.description}
  //           </Typography>
  //         </Box>
  //         <Grid container spacing={3} className="product-images">
  //           {product.fileResources.map((image, index) => (
  //             <Grid item xs={12} sm={6} md={4} key={index}>
  //               <img src={image} alt={`Product ${index}`} style={{ width: '100%', borderRadius: 8 }} />
  //             </Grid>
  //           ))}
  //         </Grid>
  //         <Box className="product-pdfs" mt={5}>
  //           <Grid container spacing={3}>
  //             {product.pdfs.map((pdf, index) => (
  //               <Grid item xs={12} key={index}>
  //                 <Box className="pdf-viewer" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
  //                   <Button
  //                     variant="outlined"
  //                     startIcon={<VisibilityIcon />}
  //                     onClick={() => handleOpen(pdf)}
  //                     color="primary"
  //                   >
  //                     Show details
  //                   </Button>
  //                 </Box>
  //               </Grid>
  //             ))}
  //           </Grid>
  //         </Box>
  //       </Paper>

  //       <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
  //         <DialogTitle>{product.title}</DialogTitle>
  //         <DialogContent>
  //           {selectedPdf && (
  //             <Box sx={{ height: '80vh' }}>
  //               <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}>
  //                 <Viewer fileUrl={selectedPdf} plugins={[defaultLayoutPluginInstance]} />
  //               </Worker>
  //             </Box>
  //           )}
  //         </DialogContent>
  //       </Dialog>
  //     </Container>
  //   );
  // };

  // export default ProductDetail;



  import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Container, Grid, Typography, Box, Paper, Button, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { Visibility as VisibilityIcon } from '@mui/icons-material';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { fetchProductById } from '../../api/service';


const ProductDetail = () => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  let { data: product, error, isLoading } = useQuery({
    queryKey: ['product', { id }],
    queryFn: fetchProductById,
    staleTime: 300000, // 5 minutes
  });

  // product = product.data
  console.log(product)

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleOpen = (pdf) => {
    setSelectedPdf(pdf);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedPdf(null);
  };

  return (
    <Container sx={{ py: 4 }}>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Box className="product-header" mb={3}>
          <Typography variant="h4" component="h1" color="primary" gutterBottom>
            {product.brandName}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {product.description}
          </Typography>
        </Box>
        <Grid container spacing={3} className="product-images">
          {product.fileResources?.map((image, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <img src={image} alt={`Product ${index}`} style={{ width: '100%', borderRadius: 8 }} />
            </Grid>
          ))}
        </Grid>
        <Box className="product-pdfs" mt={5}>
          <Grid container spacing={3}>
            {product.pdfs?.map((pdf, index) => (
              <Grid item xs={12} key={index}>
                <Box className="pdf-viewer" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Button
                    variant="outlined"
                    startIcon={<VisibilityIcon />}
                    onClick={() => handleOpen(pdf)}
                    color="primary"
                  >
                    Show details
                  </Button>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Paper>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>{product.brandName}</DialogTitle>
        <DialogContent>
          {selectedPdf && (
            <Box sx={{ height: '80vh' }}>
              <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}>
                <Viewer fileUrl={selectedPdf} plugins={[defaultLayoutPluginInstance]} />
              </Worker>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default ProductDetail;
