// src/components/MissionVision.js

import React from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import MissionIcon from '@mui/icons-material/Flag'; // Example icon for mission
import VisionIcon from '@mui/icons-material/Visibility'; // Example icon for vision
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import './MissionVision.css';

const MissionVision = () => {
  return (
    <Container maxWidth="lg" className="mission-vision">
      <Box textAlign="center" my={4}>
        <Typography variant="h4" gutterBottom>
          Mission and Vision
        </Typography>
      </Box>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <img src="/assets/images/mission-vision.jpg" alt="Mission and Vision" className="mission-vision-image" />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="mission-vision-item">
            <MissionIcon className="mission-vision-icon" />
            <Box ml={2}>
              <Typography variant="h5" gutterBottom>
                Our Mission
              </Typography>
              <Typography paragraph>
                To provide high-quality products that combine performance with value pricing, while establishing a successful relationship with our customers and our suppliers.
              </Typography>
              <Typography paragraph>
                To constantly strive to meet and exceed our customer needs and expectations of price, service, and selection. To this end, we will perform periodic reviews of the marketplace to improve our offerings.
              </Typography>
            </Box>
          </Box>
          <Box className="mission-vision-item">
            <VisionIcon className="mission-vision-icon" />
            <Box ml={2}>
              <Typography variant="h5" gutterBottom>
                Our Vision
              </Typography>
              <Typography paragraph>
                To be the leading provider in our industry, recognized for our customer satisfaction, innovative solutions, and commitment to quality.
              </Typography>
              <Typography paragraph>
                To empower communities through sustainable practices and support initiatives that enhance the overall well-being of our society.
              </Typography>
            </Box>
          </Box>

          <Box className="mission-vision-item">
            <EmojiEventsIcon className="mission-vision-icon" />
            <Box ml={2}>
              <Typography variant="h5" gutterBottom>
                Our Goals
              </Typography>
              <Typography paragraph>
                To be the leading provider in our industry, recognized for our customer satisfaction, innovative solutions, and commitment to quality.
              </Typography>
              <Typography paragraph>
                To empower communities through sustainable practices and support initiatives that enhance the overall well-being of our society.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default MissionVision;
