import React from 'react';
import ProductDetail from '../components/product/ProductDetail';
import {
  useQuery,
} from '@tanstack/react-query'
import { fetchProductData } from '../api/service';

const Product = () => {
  // const { data, isLoading, isError } = useQuery(['products'], () => fetchProductData());
  const encodedFileName = encodeURIComponent('1712750287242-CR_20240331_CR_AGI_AgamiCard(Urgent Requirement)_2024_ AGI-700_V 1.00.pdf');

  const sampleProduct = {
    title: "Sample Product",
    description: "This is a sample product description.",
    images: [
      "http://localhost:3001/uploads/b.png",
      "http://localhost:3001/uploads/b.png"
    ],
    pdfs: [
      "http://localhost:3001/uploads/1712750287242-CR_20240331_CR_AGI_AgamiCard(Urgent Requirement)_2024_ AGI-700_V 1.00.pdf"
    ]
  };

  const { isLoading, error, data:productData } = useQuery({
    queryKey: ['products'],
    queryFn: fetchProductData
  });

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
      <ProductDetail product={productData} />
    </div>
  
  );
}

export default Product;
