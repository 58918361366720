import axios from "axios";
import { BASE_URL, PRODUCT_URL, EMAIL_URL } from "../config/Url";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 10000, // Timeout in milliseconds
  headers: {
    "Content-Type": "application/json",
  },
});


// export async function fetchProductData() {
//   const response = await axiosInstance.get(PRODUCT_URL);
//   if (response.status !== 200) {
//     throw new Error('Network response was not ok');
//   }
//   return response.data;
// }

// src/api/service.js
// export const fetchProductData = async ({ pageParam = 1 }) => {
//   const response = await axiosInstance.get(PRODUCT_URL + `?page=${pageParam}`); // Adjust the URL as per your API endpoint
//   if (response.status !== 200) {
//     throw new Error('Network response was not ok');
//   }
//   const data = await response.data;
//   return {
//     products: data.data,
//     nextPage: data.hasNextPage ? pageParam + 1 : undefined,
//   };
// };

// Example fetchProductData function
export const fetchProductData = async ({ pageParam = 1 }) => {
  const response = await axiosInstance.get(`${PRODUCT_URL}?page=${pageParam}`);
  if (response.status !== 200) {
    throw new Error('Network response was not ok');
  }
  const data = response.data;
  return {
    products: data.data,
    hasNextPage: data.currentPage < data.totalPages, // Assuming this structure
    currentPage: pageParam,
  };
};



export const fetchProductById = async ({ queryKey }) => {
  const [_key, { id }] = queryKey;
  const response = await axiosInstance.get(PRODUCT_URL + `/${id}`)
  if (response.status !== 200) {
    throw new Error('Network response was not ok');
  }
  return await response.data.data;
};


export const sendContactMessage = async (formData) => {
  try {
    console.log(formData)
    const response = await axiosInstance.post(EMAIL_URL, formData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Add more API functions as needed
