// import React, { useState } from 'react';
// import { Grid, Card, CardContent, CardMedia, Typography, Button, Box, CircularProgress } from '@mui/material';
// import InfiniteScroll from 'react-infinite-scroll-component';
// import { styled } from '@mui/system';
// import { useInfiniteQuery } from '@tanstack/react-query';
// import ProductModal from './ProductModal';
// import { fetchProductData } from '../../api/service';

// // Styled components for enhanced design
// const ProductCard = styled(Card)(({ theme }) => ({
//   borderRadius: 12,
//   transition: 'transform 0.3s, box-shadow 0.3s',
//   '&:hover': {
//     transform: 'translateY(-10px)',
//     boxShadow: theme.shadows[6],
//   },
// }));

// const ProductCardContent = styled(CardContent)(({ theme }) => ({
//   textAlign: 'center',
// }));

// const ProductCardMedia = styled(CardMedia)(({ theme }) => ({
//   height: 200,
// }));

// const ProductButton = styled(Button)(({ theme }) => ({
//   marginTop: theme.spacing(2),
// }));

// const ProductGrid = () => {
//   const [selectedProduct, setSelectedProduct] = useState(null);

//   // Using useInfiniteQuery to fetch products
//   const {
//     data,
//     fetchNextPage,
//     hasNextPage,
//     isLoading,
//     isError,
//     error,
//   } = useInfiniteQuery({
//     queryKey: ['products'],
//     queryFn: fetchProductData,
//     getNextPageParam: (lastPage) => lastPage.hasNextPage ? lastPage.currentPage + 1 : undefined,
//   });

//   if (isLoading) {
//     return (
//       <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
//         <CircularProgress />
//       </Box>
//     );
//   }

//   if (isError) {
//     return <Box textAlign="center" color="error.main">Error: {error.message}</Box>;
//   }

//   // Flatten the pages array
//   const products = data.pages.flatMap(page => page.products);

//   // Handlers for opening and closing the modal
//   const handleOpenModal = (product) => {
//     console.log("Modal Click" + JSON.stringify(product));
//     setSelectedProduct(product);
//   };

//   const handleCloseModal = () => {
//     setSelectedProduct(null);
//   };

//   return (
//     <Box>
//       <Typography textAlign="center" variant="h4" component="h2" gutterBottom style={{ fontWeight: 'bold' }}>
//         Our Products
//       </Typography>
//       <Typography textAlign="center" sx={{ py: 3 }} variant="subtitle1" gutterBottom>
//         See all our popular products this week. Choose your daily needs from this list and enjoy special offers with free shipping.
//       </Typography>
//       <InfiniteScroll
//         dataLength={products.length}
//         next={fetchNextPage}
//         hasMore={hasNextPage}
//         loader={
//           <Box display="flex" justifyContent="center" alignItems="center" height="100px">
//             <CircularProgress />
//           </Box>
//         }
//         endMessage={<p style={{ textAlign: 'center' }}><b>Yay! You have seen it all</b></p>}
//       >
//         <Grid container spacing={3}>
//           {products.map((product) => (
//             <Grid item xs={12} sm={6} md={4} key={product.id}>
//               <ProductCard>
//                 <ProductCardMedia
//                   component="img"
//                   image={'/assets/images/default-product.jpg'} // Default image if none provided
//                   alt={product.brandName}
//                 />
//                 <ProductCardContent>
//                   <Typography gutterBottom variant="h5" component="div">
//                     {product.brandName}
//                   </Typography>
//                   <Typography variant="body2" color="textSecondary">
//                     {product.genericName}
//                   </Typography>
//                   <ProductButton variant="contained" color="primary" onClick={() => handleOpenModal(product)}>
//                     View Details
//                   </ProductButton>
//                 </ProductCardContent>
//               </ProductCard>
//             </Grid>
//           ))}
//         </Grid>
//       </InfiniteScroll>
//       <ProductModal product={selectedProduct} handleClose={handleCloseModal} />
//     </Box>
//   );
// };

// export default ProductGrid;

// import React, { useState } from "react";
// import {
//   Grid,
//   Card,
//   CardContent,
//   Typography,
//   Button,
//   Box,
//   CircularProgress,
// } from "@mui/material";
// import InfiniteScroll from "react-infinite-scroll-component";
// import { styled } from "@mui/system";
// import Slider from "react-slick";
// import ProductModal from "./ProductModal";
// import { fetchProductData } from "../../api/service";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import { useInfiniteQuery } from "@tanstack/react-query";
// import { HOST_URL } from "../../config/Url";

// // Styled components for enhanced design
// const ProductCard = styled(Card)(({ theme }) => ({
//   height: "100%", // Make card take full height of grid item
//   display: "flex",
//   flexDirection: "column",
//   borderRadius: 12,
//   transition: "transform 0.3s, box-shadow 0.3s",
//   "&:hover": {
//     transform: "translateY(-10px)",
//     boxShadow: theme.shadows[6],
//   },
// }));

// const ProductCardContent = styled(CardContent)(({ theme }) => ({
//   textAlign: "center",
// }));

// const ProductButton = styled(Button)(({ theme }) => ({
//   marginTop: theme.spacing(2),
// }));

// const ProductImageContainer = styled(Box)({
//   height: 200,
//   marginBottom: "16px",
// });

// const ProductGrid = () => {
//   const [selectedProduct, setSelectedProduct] = useState(null);

//   // Using useInfiniteQuery to fetch products
//   const { data, fetchNextPage, hasNextPage, isLoading, isError, error } =
//     useInfiniteQuery({
//       queryKey: ["products"],
//       queryFn: fetchProductData,
//       getNextPageParam: (lastPage) =>
//         lastPage.hasNextPage ? lastPage.currentPage + 1 : undefined,
//     });

//   if (isLoading) {
//     return (
//       <Box
//         display="flex"
//         justifyContent="center"
//         alignItems="center"
//         height="100vh"
//       >
//         <CircularProgress />
//       </Box>
//     );
//   }

//   if (isError) {
//     return (
//       <Box textAlign="center" color="error.main">
//         Error: {error.message}
//       </Box>
//     );
//   }

//   // Flatten the pages array
//   const products = data.pages.flatMap((page) => page.products);

//   const handleOpenModal = (product) => {
//     console.log("Modal Click" + JSON.stringify(product));
//     setSelectedProduct(product);
//   };

//   const handleCloseModal = () => {
//     setSelectedProduct(null);
//   };

//   // Slider settings
//   const sliderSettings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 2000,
//   };

//   const renderProductImages = (product) => {
//     const pngImages = product.fileResources.filter(
//       (file) =>
//         file.fileType === "png" ||
//         file.fileType === "jpg" ||
//         file.fileType === "jpeg"
//     );

//     if (pngImages.length === 0) {
//       return (
//         <ProductImageContainer>
//           <img
//             src="/assets/images/default-image.jpg"
//             alt="Default product"
//             style={{ height: "100%", width: "100%", objectFit: "cover" }}
//           />
//         </ProductImageContainer>
//       );
//     }

//     if (pngImages.length === 1) {
//       // Render a single image without the Slider
//       return (
//         <ProductImageContainer>
//           <img
//             src={`${HOST_URL}/${pngImages[0].filePath}`}
//             alt={product.brandName}
//             style={{ height: 200, width: "100%", objectFit: "cover" }}
//           />
//         </ProductImageContainer>
//       );
//     }

//     // Render multiple images with the Slider
//     return (
//       <ProductImageContainer>
//         <Slider {...sliderSettings}>
//           {pngImages.map((file, index) => (
//             <div key={index}>
//               <img
//                 src={`${HOST_URL}/${file.filePath}`}
//                 alt={`${product.brandName} - ${index + 1}`}
//                 style={{ height: 200, width: "100%", objectFit: "cover" }}
//               />
//             </div>
//           ))}
//         </Slider>
//       </ProductImageContainer>
//     );
//   };

//   return (
//     <Box>
//       <Typography
//         textAlign="center"
//         variant="h4"
//         component="h2"
//         gutterBottom
//         style={{ fontWeight: "bold" }}
//       >
//         Our Products
//       </Typography>
//       <Typography
//         textAlign="center"
//         sx={{ py: 3 }}
//         variant="subtitle1"
//         gutterBottom
//       >
//         See all our popular products this week. Choose your daily needs from
//         this list and enjoy special offers with free shipping.
//       </Typography>
//       <InfiniteScroll
//         dataLength={products && products.length > 0}
//         next={fetchNextPage}
//         hasMore={hasNextPage}
//         loader={
//           <Box
//             display="flex"
//             justifyContent="center"
//             alignItems="center"
//             height="100px"
//           >
//             <CircularProgress />
//           </Box>
//         }
//         endMessage={
//           <p style={{ textAlign: "center" }}>
//             <b>Yay! You have seen it all</b>
//           </p>
//         }
//       >
//         <Grid container spacing={3}>
//           {products && products.length > 0 && products.map((product) => (
//             <Grid item xs={12} sm={6} md={4} key={product.id}>
//               <ProductCard>
//                 <ProductCardContent>
//                   {renderProductImages(product)}
//                   <Typography gutterBottom variant="h5" component="div" py={3}>
//                     {product.brandName}
//                   </Typography>
//                   <Typography variant="body2" color="textSecondary">
//                     {product.genericName}
//                   </Typography>
//                   <ProductButton
//                     variant="contained"
//                     color="primary"
//                     onClick={() => handleOpenModal(product)}
//                   >
//                     View Details
//                   </ProductButton>
//                 </ProductCardContent>
//               </ProductCard>
//             </Grid>
//           ))}
//         </Grid>
//       </InfiniteScroll>
//       <ProductModal product={selectedProduct} handleClose={handleCloseModal} />
//     </Box>
//   );
// };

// export default ProductGrid;


import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  CircularProgress,
  Pagination,
} from "@mui/material";
import { styled } from "@mui/system";
import Slider from "react-slick";
import ProductModal from "./ProductModal";
import { fetchProductData } from "../../api/service";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useQuery } from "@tanstack/react-query";
import { HOST_URL } from "../../config/Url";

// Styled components (unchanged)
const ProductCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  borderRadius: 12,
  transition: "transform 0.3s, box-shadow 0.3s",
  "&:hover": {
    transform: "translateY(-10px)",
    boxShadow: theme.shadows[6],
  },
}));

const ProductCardContent = styled(CardContent)(({ theme }) => ({
  textAlign: "center",
}));

const ProductButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const ProductImageContainer = styled(Box)({
  height: 200,
  marginBottom: "16px",
});

const ProductGrid = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [page, setPage] = useState(1);
  const productsPerPage = 12; // Adjust as needed

  // Using useQuery instead of useInfiniteQuery
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["products", page],
    queryFn: () => fetchProductData({ page, limit: productsPerPage }),
  });

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box textAlign="center" color="error.main">
        Error: {error.message}
      </Box>
    );
  }

  const { products, totalPages } = data;

  const handleOpenModal = (product) => {
    setSelectedProduct(product);
  };

  const handleCloseModal = () => {
    setSelectedProduct(null);
  };

  // Slider settings (unchanged)
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const renderProductImages = (product) => {
    const pngImages = product.fileResources.filter(
      (file) =>
        file.fileType === "png" ||
        file.fileType === "jpg" ||
        file.fileType === "jpeg"
    );

    if (pngImages.length === 0) {
      return (
        <ProductImageContainer>
          <img
            src="/assets/images/default-image.jpg"
            alt="Default product"
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          />
        </ProductImageContainer>
      );
    }

    if (pngImages.length === 1) {
      return (
        <ProductImageContainer>
          <img
            src={`${HOST_URL}/${pngImages[0].filePath}`}
            alt={product.brandName}
            style={{ height: 200, width: "100%", objectFit: "cover" }}
          />
        </ProductImageContainer>
      );
    }

    return (
      <ProductImageContainer>
        <Slider {...sliderSettings}>
          {pngImages.map((file, index) => (
            <div key={index}>
              <img
                src={`${HOST_URL}/${file.filePath}`}
                alt={`${product.brandName} - ${index + 1}`}
                style={{ height: 200, width: "100%", objectFit: "cover" }}
              />
            </div>
          ))}
        </Slider>
      </ProductImageContainer>
    );
  };

  return (
    <Box>
      <Typography
        textAlign="center"
        variant="h4"
        component="h2"
        gutterBottom
        style={{ fontWeight: "bold" }}
      >
        Our Products
      </Typography>
      <Typography
        textAlign="center"
        sx={{ py: 3 }}
        variant="subtitle1"
        gutterBottom
      >
        See all our popular products this week. Choose your daily needs from
        this list and enjoy special offers with free shipping.
      </Typography>
      <Grid container spacing={3}>
        {products && products.length > 0 && products.map((product) => (
          <Grid item xs={12} sm={6} md={4} key={product.id}>
            <ProductCard>
              <ProductCardContent>
                {renderProductImages(product)}
                <Typography gutterBottom variant="h5" component="div" py={3}>
                  {product.brandName}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {product.genericName}
                </Typography>
                <ProductButton
                  variant="contained"
                  color="primary"
                  onClick={() => handleOpenModal(product)}
                >
                  View Details
                </ProductButton>
              </ProductCardContent>
            </ProductCard>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, mb: 4 }}>
        <Pagination 
          count={totalPages} 
          page={page} 
          onChange={handlePageChange}
          color="primary"
          size="large"
        />
      </Box>
      <ProductModal product={selectedProduct} handleClose={handleCloseModal} />
    </Box>
  );
};

export default ProductGrid;