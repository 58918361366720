// import React from 'react';
// import { Container, Typography, Grid, TextField, Button, Box, Paper } from '@mui/material';
// import EmailIcon from '@mui/icons-material/Email';
// import PhoneIcon from '@mui/icons-material/Phone';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
// import './ContactUs.css';

// const ContactUs = () => {
//   return (
//     <Container maxWidth="lg" className="contact-us">
//       {/* <Box className="contact-us-banner">
//         <img src="/assets/images/banner.jpg" alt="Contact Us Banner" className="banner-image" />
//       </Box> */}
//       <Box className="contact-info" sx={{ py: 5, textAlign: 'center', }}>
//         <Typography variant="h4" gutterBottom>Contact Us</Typography>
//         <Grid container spacing={3} justifyContent="center">
//           <Grid item xs={12} sm={6} md={4}>
//             <Paper elevation={3} sx={{ p: 3, textAlign: 'center' }}>
//               <EmailIcon fontSize="large" color="primary" />
//               <Typography variant="h6">Email Us</Typography>
//               <Typography variant="body1">
//                 <a href="mailto:info@sarpharmainternational.com">info@sarpharmainternational.com</a>
//               </Typography>
//               <Typography variant="body2">
//                 Interactively grow empowered for process-centric total linkage.
//               </Typography>
//             </Paper>
//           </Grid>
//           <Grid item xs={12} sm={6} md={4}>
//             <Paper elevation={3} sx={{ p: 3, textAlign: 'center' }}>
//               <PhoneIcon fontSize="large" color="primary" />
//               <Typography variant="h6">Call Us</Typography>
//               <Typography variant="body1">
//                 <a href="tel:029-00142687">029-00142687</a>
//               </Typography>
//               <Typography variant="body2">
//                 Distinctively disseminate focused solutions clicks-and-mortar ministate.
//               </Typography>
//             </Paper>
//           </Grid>
//           <Grid item xs={12} sm={6} md={4}>
//             <Paper elevation={3} sx={{ p: 3, textAlign: 'center' }}>
//               <LocationOnIcon fontSize="large" color="primary" />
//               <Typography variant="h6">Location</Typography>
//               <Typography variant="body1">
//                 Boho One, Bridge Street West, Middlesbrough, North Yorkshire, TS2 1AE.
//               </Typography>
//               <Typography variant="body1">
//                 561-4535 Nulla LA United States 96522.
//               </Typography>
//             </Paper>
//           </Grid>
//         </Grid>
//       </Box>
//       <Box className="contact-form-section" sx={{ py: 5, textAlign: 'center' }}>
//         <Typography variant="h5" gutterBottom>For any support just send your query</Typography>
//         <Typography variant="body1" sx={{ mb: 5, maxWidth: '600px', mx: 'auto' }}>
//           Collaboratively promote client-focused convergence via customer-directed alignments via plagiarized strategic users and standardized infrastructures.
//         </Typography>
//         <Box component="form" sx={{ maxWidth: '600px', mx: 'auto' }}>
//           <Grid container spacing={2}>
//             <Grid item xs={12} sm={6}>
//               <TextField fullWidth label="Enter Your Name" name="name" required variant="outlined" />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TextField fullWidth label="Enter Your Email" name="email" required variant="outlined" type="email" />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField fullWidth label="Enter Your Subject" name="subject" required variant="outlined" />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField fullWidth label="Write your message here" name="message" required variant="outlined" multiline rows={4} />
//             </Grid>
//             <Grid item xs={12}>
//               <Button type="submit" variant="contained" color="primary" fullWidth>Send Message</Button>
//             </Grid>
//           </Grid>
//         </Box>
//       </Box>
//     </Container>
//   );
// };

// export default ContactUs;


import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { Container, Box, Typography, Paper, Grid, TextField, Button, Link } from '@mui/material';
import { LocationOn, Email, SupportAgent } from '@mui/icons-material';
import axios from 'axios';
import { sendContactMessage } from '../../api/service';

// Assuming you have an API endpoint for form submission
const submitContactForm = async (formData) => {
  const response = await axios.post('https://api.example.com/contact', formData);
  return response.data;
};

const ContactUs = () => {
  const mutation = useMutation({
    mutationFn: sendContactMessage,
    onSuccess: (data) => {
      // Handle successful submission
      console.log('Form submitted successfully', data);
      // You might want to show a success message to the user here
    },
    onError: (error) => {
      // Handle submission error
      console.error('Error submitting form', error);
      // You might want to show an error message to the user here
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const formObject = Object.fromEntries(formData.entries());
    mutation.mutate(formObject);
  };

  return (
    <Container maxWidth="lg" className="contact-info">
      <Box py={5}>
        <Typography variant="h3" align="center" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="subtitle1" align="center" gutterBottom>
          Get all our info and also can message us directly from here
        </Typography>
      </Box>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Grid container spacing={4} >
          <Grid item xs={12} md={6}>
            <Box component="form" onSubmit={handleSubmit}>
              <TextField
                fullWidth
                name="name"
                label="Name*"
                placeholder="Your full name"
                margin="normal"
                required
              />
              <TextField
                fullWidth
                name="from"
                label="Email*"
                placeholder="demo@domain.com"
                margin="normal"
                required
                type="email"
              />
              <TextField
                fullWidth
                name="subject"
                label="Subject*"
                placeholder="Email Subject"
                margin="normal"
                required
              />
              <TextField
                fullWidth
                name="contact"
                label="Contact Number*"
                placeholder="01700000000"
                margin="normal"
                required
              />
              <TextField
                fullWidth
                name="message"
                label="Your message*"
                multiline
                rows={4}
                margin="normal"
                required
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 2,
                  bgcolor: '#000',
                  color: '#fff',
                  '&:hover': {
                    bgcolor: '#333',
                  },
                }}
                disabled={mutation.isLoading}
              >
                {mutation.isLoading ? 'SENDING...' : 'SEND MESSAGE'}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} my={4}>
            <Box>
              <Box display="flex" alignItems="center" mb={3}>
                <LocationOn sx={{ color: 'text.secondary', mr: 2, fontSize: 40 }} />
                <Box>
                  <Typography variant="h6">Our Address</Typography>
                  <Typography variant="body2" color="text.secondary">
                    1012 Pebda Parkway, Mirpur 2
                    <br />
                    Dhaka, Bangladesh
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" mb={3}>
                <Email sx={{ color: 'text.secondary', mr: 2, fontSize: 40 }} />
                <Box>
                  <Typography variant="h6">Contact Info</Typography>
                  <Typography variant="body2" color="text.secondary">
                    Open a chat or give us call at
                    <br />
                    <Link href="tel:3108415500" color="inherit" sx={{ color: '#FF4081', textDecoration: 'none' }}>
                      310.841.5500
                    </Link>
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" mb={3}>
                <SupportAgent sx={{ color: 'text.secondary', mr: 2, fontSize: 40 }} />
                <Box>
                  <Typography variant="h6">Live Support</Typography>
                  <Typography variant="body2" color="text.secondary">
                    Urgent? Go to live chat portal
                    <br />
                    <Link href="https://www.supportlive.com" color="inherit" target="_blank" sx={{ color: '#FF4081', textDecoration: 'none' }}>
                      www.supportlive.com
                    </Link>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default ContactUs;