import React from 'react';
import { CssBaseline, Container, AppBar, Toolbar, Typography } from '@mui/material';
import GenericBrand from './GenericBrand';
import InternationalBrand from './InternationalBrand';

function BrandMedicine() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container>
        <GenericBrand />
        <InternationalBrand />
      </Container>
    </React.Fragment>
  );
}

export default BrandMedicine;
