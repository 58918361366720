// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   InputBase,
//   IconButton,
//   List,
//   ListItem,
//   ListItemText,
//   Paper,
//   ClickAwayListener,
// } from '@mui/material';
// import SearchIcon from '@mui/icons-material/Search';

// // Mock suggestions - replace with your actual data or API call
// const mockSuggestions = [
//   'Paracetamol', 'Ibuprofen', 'Aspirin', 'Amoxicillin', 'Atorvastatin',
//   'Metformin', 'Losartan', 'Omeprazole', 'Simvastatin', 'Lisinopril',
// ];

// const SearchBar = () => {
//   const [searchValue, setSearchValue] = useState('');
//   const [suggestions, setSuggestions] = useState([]);
//   const [showSuggestions, setShowSuggestions] = useState(false);

//   useEffect(() => {
//     if (searchValue) {
//       const filteredSuggestions = mockSuggestions.filter(suggestion =>
//         suggestion.toLowerCase().includes(searchValue.toLowerCase())
//       );
//       setSuggestions(filteredSuggestions);
//       setShowSuggestions(true);
//     } else {
//       setSuggestions([]);
//       setShowSuggestions(false);
//     }
//   }, [searchValue]);

//   const handleInputChange = (e) => {
//     setSearchValue(e.target.value);
//   };

//   const handleSuggestionClick = (suggestion) => {
//     setSearchValue(suggestion);
//     setShowSuggestions(false);
//   };

//   return (
//     <ClickAwayListener onClickAway={() => setShowSuggestions(false)}>
//       <Box className="search-bar">
//           <InputBase
//             placeholder="Search by products/generic name"
//             inputProps={{ 'aria-label': 'search' }}
//             className="search-input"
//             value={searchValue}
//             onChange={handleInputChange}
//             sx={{ ml: 1, flex: 1 }}
//           />
//           <IconButton type="submit" aria-label="search" className="search-button">
//             <SearchIcon />
//           </IconButton>
        
//         {showSuggestions && suggestions.length > 0 && (
//           <Paper 
//             elevation={3}
//             sx={{
//               position: 'absolute',
//               top: '100%',
//               left: 0,
//               right: 0,
//               zIndex: 1,
//               mt: 1,
//               maxHeight: '300px',
//               overflow: 'auto',
//             }}
//           >
//             <List>
//               {suggestions.map((suggestion, index) => (
//                 <ListItem 
//                   key={index} 
//                   button 
//                   onClick={() => handleSuggestionClick(suggestion)}
//                   sx={{
//                     '&:hover': {
//                       backgroundColor: '#f5f5f5',
//                     },
//                   }}
//                 >
//                   <ListItemText primary={suggestion} />
//                 </ListItem>
//               ))}
//             </List>
//           </Paper>
//         )}
//       </Box>
//     </ClickAwayListener>
//   );
// };

// export default SearchBar;


// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import {
//   Box,
//   InputBase,
//   IconButton,
//   List,
//   ListItem,
//   ListItemText,
//   Paper,
//   ClickAwayListener,
// } from '@mui/material';
// import SearchIcon from '@mui/icons-material/Search';

// // Mock suggestions with IDs - replace with your actual data or API call
// const mockSuggestions = [
//   { id: 1, name: 'Paracetamol' },
//   { id: 2, name: 'Ibuprofen' },
//   { id: 3, name: 'Aspirin' },
//   { id: 4, name: 'Amoxicillin' },
//   { id: 5, name: 'Atorvastatin' },
//   // ... add more items as needed
// ];

// const SearchBar = () => {
//   const [searchValue, setSearchValue] = useState('');
//   const [suggestions, setSuggestions] = useState([]);
//   const [showSuggestions, setShowSuggestions] = useState(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (searchValue) {
//       const filteredSuggestions = mockSuggestions.filter(suggestion =>
//         suggestion.name.toLowerCase().includes(searchValue.toLowerCase())
//       );
//       setSuggestions(filteredSuggestions);
//       setShowSuggestions(true);
//     } else {
//       setSuggestions([]);
//       setShowSuggestions(false);
//     }
//   }, [searchValue]);

//   const handleInputChange = (e) => {
//     setSearchValue(e.target.value);
//   };

//   const handleSuggestionClick = (suggestion) => {
//     setSearchValue(suggestion.name);
//     setShowSuggestions(false);
//     // Navigate to the details page using the product ID
//     navigate(`/product/${suggestion.id}`);
//   };

//   const handleSearch = () => {
//     if (searchValue) {
//       // Find the first matching suggestion
//       const matchingSuggestion = suggestions.find(suggestion => 
//         suggestion.name.toLowerCase() === searchValue.toLowerCase()
//       );
//       if (matchingSuggestion) {
//         navigate(`/product/${matchingSuggestion.id}`);
//       } else {
//         // Handle case when no exact match is found
//         // You might want to navigate to a search results page instead
//         console.log('No exact match found');
//       }
//     }
//   };

//   return (
//     <ClickAwayListener onClickAway={() => setShowSuggestions(false)}>
//       <Box className="search-bar">
        
//           <InputBase
//             placeholder="Search by products/generic name"
//             inputProps={{ 'aria-label': 'search' }}
//             className="search-input"
//             value={searchValue}
//             onChange={handleInputChange}
//             onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
//             sx={{ ml: 1, flex: 1 }}
//           />
//           <IconButton 
//             type="submit" 
//             aria-label="search" 
//             className="search-button"
//             onClick={handleSearch}
//           >
//             <SearchIcon />
//           </IconButton>
        
//         {showSuggestions && suggestions.length > 0 && (
//           <Paper 
//             elevation={3}
//             sx={{
//               position: 'absolute',
//               top: '100%',
//               left: 0,
//               right: 0,
//               zIndex: 1,
//               mt: 1,
//               maxHeight: '300px',
//               overflow: 'auto',
//             }}
//           >
//             <List>
//               {suggestions.map((suggestion) => (
//                 <ListItem 
//                   key={suggestion.id} 
//                   button 
//                   onClick={() => handleSuggestionClick(suggestion)}
//                   sx={{
//                     '&:hover': {
//                       backgroundColor: '#f5f5f5',
//                     },
//                   }}
//                 >
//                   <ListItemText primary={suggestion.name} />
//                 </ListItem>
//               ))}
//             </List>
//           </Paper>
//         )}
//       </Box>
//     </ClickAwayListener>
//   );
// };

// export default SearchBar;

// import React, { useState, useEffect } from 'react';
// import { useQuery } from '@tanstack/react-query';
// import {
//   Box,
//   InputBase,
//   IconButton,
//   List,
//   ListItem,
//   ListItemText,
//   Paper,
//   ClickAwayListener,
//   CircularProgress,
// } from '@mui/material';
// import SearchIcon from '@mui/icons-material/Search';
// import ProductModal from './product/ProductModal';
// import { fetchProductData } from '../api/service';

// const SearchBar = () => {
//   const [searchValue, setSearchValue] = useState('');
//   const [suggestions, setSuggestions] = useState([]);
//   const [showSuggestions, setShowSuggestions] = useState(false);
//   const [selectedProduct, setSelectedProduct] = useState(null);
//   const [modalOpen, setModalOpen] = useState(false);

//   // Use React Query to fetch products
//   const { data: products, isLoading, isError } = useQuery({
//     queryKey: ['products'],
//     queryFn: fetchProductData,
//     staleTime: 5 * 60 * 1000, // 5 minutes
//   });

//   useEffect(() => {
//     if (searchValue && products) {
//       const filteredSuggestions = products.filter(product =>
//         product.brandName.toLowerCase().includes(searchValue.toLowerCase()) ||
//         product.genericName.toLowerCase().includes(searchValue.toLowerCase())
//       );
//       setSuggestions(filteredSuggestions);
//       setShowSuggestions(true);
//     } else {
//       setSuggestions([]);
//       setShowSuggestions(false);
//     }
//   }, [searchValue, products]);

//   const handleInputChange = (e) => {
//     setSearchValue(e.target.value);
//   };

//   const handleSuggestionClick = (product) => {
//     setSearchValue(product.brandName);
//     setShowSuggestions(false);
//     setSelectedProduct(product);
//     setModalOpen(true);
//   };

//   const handleSearch = () => {
//     if (searchValue && products) {
//       const matchingProduct = products.find(product => 
//         product.brandName.toLowerCase() === searchValue.toLowerCase() ||
//         product.genericName.toLowerCase() === searchValue.toLowerCase()
//       );
//       if (matchingProduct) {
//         setSelectedProduct(matchingProduct);
//         setModalOpen(true);
//       } else {
//         console.log('No exact match found');
//       }
//     }
//   };

//   const handleCloseModal = () => {
//     setModalOpen(false);
//     setSelectedProduct(null);
//   };

//   if (isLoading) {
//     return <CircularProgress />;
//   }

//   if (isError) {
//     return <div>Error loading products. Please try again later.</div>;
//   }

//   return (
//     <>
//       <ClickAwayListener onClickAway={() => setShowSuggestions(false)}>
//         <Box className="search-bar">
//           <InputBase
//             placeholder="Search by brand or generic name"
//             inputProps={{ 'aria-label': 'search' }}
//             className="search-input"
//             value={searchValue}
//             onChange={handleInputChange}
//             onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
//             sx={{ ml: 1, flex: 1 }}
//           />
//           <IconButton
//             type="submit"
//             aria-label="search"
//             className="search-button"
//             onClick={handleSearch}
//           >
//             <SearchIcon />
//           </IconButton>
          
//           {showSuggestions && suggestions.length > 0 && (
//             <Paper
//               elevation={3}
//               sx={{
//                 position: 'absolute',
//                 top: '100%',
//                 left: 0,
//                 right: 0,
//                 zIndex: 1,
//                 mt: 1,
//                 maxHeight: '300px',
//                 overflow: 'auto',
//               }}
//             >
//               <List>
//                 {suggestions.map((product) => (
//                   <ListItem
//                     key={product.id}
//                     button
//                     onClick={() => handleSuggestionClick(product)}
//                     sx={{
//                       '&:hover': {
//                         backgroundColor: '#f5f5f5',
//                       },
//                     }}
//                   >
//                     <ListItemText 
//                       primary={product.brandName} 
//                       secondary={product.genericName}
//                     />
//                   </ListItem>
//                 ))}
//               </List>
//             </Paper>
//           )}
//         </Box>
//       </ClickAwayListener>

//       {selectedProduct && (
//         <ProductModal
//           product={selectedProduct}
//           open={modalOpen}
//           handleClose={handleCloseModal}
//         />
//       )}
//     </>
//   );
// };

// export default SearchBar;



import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  Box,
  InputBase,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  ClickAwayListener,
  CircularProgress,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ProductModal from './product/ProductModal';
import { fetchProductData } from '../api/service';

const SearchBar = () => {
  const [searchValue, setSearchValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  // Use React Query to fetch products
  const { data, isLoading, isError } = useQuery({
    queryKey: ['products'],
    queryFn: fetchProductData,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  // Extract products from the data
  const products = Array.isArray(data) ? data : data?.products || [];

  useEffect(() => {
    if (searchValue && products.length > 0) {
      const filteredSuggestions = products.filter(product =>
        product.brandName.toLowerCase().includes(searchValue.toLowerCase()) ||
        product.genericName.toLowerCase().includes(searchValue.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
      setShowSuggestions(true);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  }, [searchValue, products]);

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSuggestionClick = (product) => {
    setSearchValue(product.brandName);
    setShowSuggestions(false);
    setSelectedProduct(product);
    setModalOpen(true);
  };

  const handleSearch = () => {
    if (searchValue && products.length > 0) {
      const matchingProduct = products.find(product => 
        product.brandName.toLowerCase() === searchValue.toLowerCase() ||
        product.genericName.toLowerCase() === searchValue.toLowerCase()
      );
      if (matchingProduct) {
        setSelectedProduct(matchingProduct);
        setModalOpen(true);
      } else {
        console.log('No exact match found');
      }
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedProduct(null);
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <div>Error loading products. Please try again later.</div>;
  }

  return (
    <>
      <ClickAwayListener onClickAway={() => setShowSuggestions(false)}>
        <Box className="search-bar">
          <InputBase
            placeholder="Search by brand or generic name"
            inputProps={{ 'aria-label': 'search' }}
            className="search-input"
            value={searchValue}
            onChange={handleInputChange}
            onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
            sx={{ ml: 1, flex: 1 }}
          />
          <IconButton
            type="submit"
            aria-label="search"
            className="search-button"
            onClick={handleSearch}
          >
            <SearchIcon />
          </IconButton>
          
          {showSuggestions && suggestions.length > 0 && (
            <Paper
              elevation={3}
              sx={{
                position: 'absolute',
                top: '100%',
                left: 0,
                right: 0,
                zIndex: 1,
                mt: 1,
                maxHeight: '300px',
                overflow: 'auto',
              }}
            >
              <List>
                {suggestions.map((product) => (
                  <ListItem
                    key={product.id}
                    button
                    onClick={() => handleSuggestionClick(product)}
                    sx={{
                      '&:hover': {
                        backgroundColor: '#f5f5f5',
                      },
                    }}
                  >
                    <ListItemText 
                      primary={product.brandName} 
                      secondary={product.genericName}
                    />
                  </ListItem>
                ))}
              </List>
            </Paper>
          )}
        </Box>
      </ClickAwayListener>

      {selectedProduct && (
        <ProductModal
          product={selectedProduct}
          open={modalOpen}
          handleClose={handleCloseModal}
        />
      )}
    </>
  );
};

export default SearchBar;