import React from 'react';
import { Button, Typography, Container, Box } from '@mui/material';

const NotFound = () => {
  return (
    <div className="not-found-container" style={{ minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <Container maxWidth="sm">
        <Box sx={{ textAlign: 'center', mb: 2 }}>
          <img src="./assets/images/404.png" alt="404 Not Found" style={{ maxWidth: '100%', height: 'auto' }} />
        </Box>
        <Button variant="contained" color="primary" href="/" fullWidth>
          BACK TO HOMEPAGE
        </Button>
      </Container>
    </div>
  );
};

export default NotFound;
