import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import './Auth.css';

const Login = ({ onClose, onRegisterClick }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleSubmit = (e) => {
    e.preventDefault();
    alert('Logged in');
    onClose();
  };

  return (
    <Box className="authModalContent">
      <IconButton className="closeButton" onClick={onClose} sx={{ position: 'absolute', top: 0, right: 0 }}>
        <Close />
      </IconButton>
      <Typography variant="h5" gutterBottom>
        Login
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
        >
          Login
        </Button>
      </form>
      <Grid container justifyContent="center">
        <Grid item>
          <Button onClick={onRegisterClick} color="primary">
            Don't have an account? Register
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
