import React from 'react';
import { Typography } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: 20 }}>
      <Typography variant="h4">Privacy Policy</Typography>
      <br />
      <Typography variant="body1">
        This Privacy Policy describes how [Your Company Name] ("we," "us," or
        "our") collects, uses, and discloses your personal information when you
        use our website ([Your Website URL]) (the "Site").
      </Typography>
      <br />
      <Typography variant="h5">Information We Collect</Typography>
      <br />
      <Typography variant="body1">
        We may collect the following types of information when you use the Site:
      </Typography>
      <ul style={{ listStyleType: 'disc', paddingLeft: 20 }}>
        <li>
          <Typography variant="body1">Personal Information:</Typography>
          <ul style={{ listStyleType: 'circle', paddingLeft: 20 }}>
            <li>
              <Typography variant="body1">Name (optional)</Typography>
            </li>
            <li>
              <Typography variant="body1">Email address</Typography>
            </li>
          </ul>
        </li>
        <li>
          <Typography variant="body1">
            Usage Data: We may also collect information about how you access and
            use the Site, such as the pages you view, the time you spend on those
            pages, and the links you click.
          </Typography>
        </li>
      </ul>
      <br />
      <Typography variant="h5">How We Use Your Information</Typography>
      <br />
      <Typography variant="body1">
        We may use the information we collect for the following purposes:
      </Typography>
      <ul style={{ listStyleType: 'disc', paddingLeft: 20 }}>
        <li>
          <Typography variant="body1">To operate and maintain the Site</Typography>
        </li>
        <li>
          <Typography variant="body1">
            To send you information about our products and services
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            To respond to your inquiries and requests
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            To improve the Site and your experience
          </Typography>
        </li>
      </ul>
      <br />
      <Typography variant="h5">Disclosure of Your Information</Typography>
      <br />
      <Typography variant="body1">
        We may disclose your information to third-party vendors and service
        providers who help us operate and maintain the Site. These third parties
        are obligated to keep your information confidential and to use it only for
        the purposes we have disclosed.
      </Typography>
      <br />
      <Typography variant="h5">Your Rights</Typography>
      <br />
      <Typography variant="body1">
        You may have certain rights with respect to your personal information,
        depending on the law that applies to you. These rights may include the
        right to access, correct, or delete your personal information.
      </Typography>
      <br />
      <Typography variant="h5">Changes to This Privacy Policy</Typography>
      <br />
      <Typography variant="body1">
        We may update this Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on the Site.
      </Typography>
      <br />
      <Typography variant="h5">Contact Us</Typography>
      <br />
      <Typography variant="body1">
        If you have any questions about this Privacy Policy, please contact us at:
      </Typography>
      <Typography variant="body1">[Your Email Address]</Typography>
    </div>
  );
};

export default PrivacyPolicy;
