// src/components/HowItWorks.js

import React from 'react';
import { Container, Grid, Card, CardContent, Typography, Accordion, AccordionSummary, AccordionDetails, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const steps = [
  { title: 'Make An Enquiry', description: 'Use our search bar on the homepage or select medicine from the page.', step: 1, details: [
    "Use our search bar on the homepage or select medicine from the page.",
    "Once on the medicine page, click on “Submit an enquiry” to leave us your details.",
    "You can also make enquiry via any of our social media like WhatsApp, LinkedIn, Instagram etc or mail us."
  ] },
  { title: 'Receive a Quote', description: 'Get the best possible quote for the medicine you need.', step: 2, details: [
    "You will receive a quote as per your required quantities.",
    "The cost of shipping like DHL, FedEx or UPS will be added as per the shipping destination."
  ] },
  { title: 'Order Confirmation', description: 'Confirm your order after reviewing the quote.', step: 3, details: [
    "You must confirm the order.",
    "For patient supply, you have to share your prescription with details."
  ] },
  { title: 'Generate Proforma Invoice', description: 'An invoice is generated for your order.', step: 4, details: [
    "We will provide you an invoice with details of price and other cost."
  ] },
  { title: 'Payment Confirmation', description: 'Make the payment and receive confirmation.', step: 5, details: [
    "Make payment of your medication conveniently, through bank or other money transfer process."
  ] },
  { title: 'Delivery & Shipping', description: 'Your order is shipped and delivered to your address.', step: 6, details: [
    "After you have paid for the order, we will process your order.",
    "Our experienced logistics team will collect your medicine and arrange for the safe shipping.",
    "We will keep you informed every step of the way."
  ] },
];

const HowItWorks = () => {
  return (
    <Container maxWidth="lg" style={{ textAlign: 'center', padding: '50px 0' }}>
      <Typography variant="h4" component="h2" gutterBottom style={{ fontWeight: 'bold' }}>
        How it works
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        We help you access your desired medicines with the lowest possible price
      </Typography>
      <Grid container spacing={3} style={{ position: 'relative', marginTop: '20px' }}>
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <Grid item xs={12} sm={6} md={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
              <Card style={{ width: '100%', borderTop: `5px solid ${index % 2 === 0 ? '#4CAF50' : '#00ACC1'}`, borderRadius: '10px', position: 'relative' }}>
                <CardContent>
                  <Typography variant="h5" component="h3" style={{ marginBottom: '10px', fontWeight: 'bold' }}>
                    Step {step.step}
                  </Typography>
                  <Typography variant="h6" component="h4" style={{ marginBottom: '10px' }}>
                    {step.title}
                  </Typography>
                </CardContent>
              </Card>
              {index < steps.length - 1 && (
                <div style={{ marginLeft: '-24px', zIndex: 1 }}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 0L24 12L12 24L0 12L12 0Z" fill="#00ACC1"/>
                  </svg>
                </div>
              )}
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      <div style={{ marginTop: '40px' }}>
        {steps.map((step, index) => (
          <Accordion key={index} style={{ marginBottom: '10px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
              style={{ backgroundColor: '#f5f5f5', padding: '10px 20px', borderRadius: '10px' }}
            >
              <Typography variant="h6">Step {step.step}: {step.title}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: '#fafafa', padding: '20px' }}>
              <ul style={{ listStyle: 'none', padding: 0 }}>
                {step.details.map((detail, i) => (
                  <li key={i} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <CheckCircleIcon style={{ marginRight: '8px', color: '#4CAF50' }} />
                    <Typography variant="body2" color="textSecondary">{detail}</Typography>
                  </li>
                ))}
              </ul>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </Container>
  );
};

export default HowItWorks;
