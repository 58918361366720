// src/components/ContractManufacturing.js

import React from 'react';
import { Container, Typography, Box, Grid, Paper, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';
import SecurityIcon from '@mui/icons-material/Security';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LockIcon from '@mui/icons-material/Lock';
import './ContractManufacturing.css';

const ContractManufacturing = () => {
  return (
    <Container maxWidth="lg" className="contract-manufacturing">
      <Box textAlign="center" my={4}>
        <Typography variant="overline" display="block" gutterBottom>
          OUR SERVICES
        </Typography>
        <Typography variant="h4" gutterBottom>
          Contract Manufacturing
        </Typography>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box className="service-description">
            <Typography variant="body1" paragraph>
              SAR Pharma International provides comprehensive contract manufacturing services for bulk supply of pharmaceutical items. With our expertise in the global distribution of medicines, we understand the unique needs of businesses in the pharmaceutical industry.
            </Typography>
            <Typography variant="body1" paragraph>
              Our contract manufacturing services offer a reliable solution for pharmaceutical companies looking to outsource their manufacturing needs. We have state-of-the-art facilities and a highly skilled team capable of handling large-scale production requirements. From formulation development to manufacturing, packaging, and labeling, we ensure adherence to strict quality standards and regulatory guidelines.
            </Typography>
            <Typography variant="body1" paragraph>
              At SAR Pharma, we prioritize the authenticity, safety, and efficacy of the products we manufacture. Our dedicated quality control team conducts rigorous testing and inspection throughout the manufacturing process to ensure that the highest quality standards are maintained.
            </Typography>
          </Box>
        </Grid>
        
      </Grid>
      <Grid container spacing={4} my={4}>
      <Grid item xs={12}>
          <Paper className="service-highlights" elevation={3}>
            <List>
              <ListItem>
                <ListItemIcon>
                  <BuildIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary="Scalable Production Capacity"
                  secondary="Our facilities are equipped to handle large-volume production, accommodating your bulk supply needs."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <SecurityIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary="Quality Assurance"
                  secondary="We maintain stringent quality control measures to ensure the authenticity and safety of the products we manufacture."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <VerifiedUserIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary="Regulatory Compliance"
                  secondary="Our manufacturing processes adhere to international regulatory guidelines, ensuring compliance with industry standards."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <AccessTimeIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary="Timely Delivery"
                  secondary="We understand the importance of meeting deadlines. Our efficient production and supply chain management ensures the timely delivery of your manufactured products."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <LockIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary="Confidentiality and Intellectual Property Protection"
                  secondary="We prioritize the confidentiality of your formulations and intellectual property, implementing robust measures to safeguard your proprietary information."
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Grid>
      <Box textAlign="center" my={4}>
        <Typography variant="body1" paragraph>
          SAR Pharma International is your trusted partner for contract manufacturing, offering reliable and efficient solutions for your bulk pharmaceutical supply requirements.
        </Typography>
      </Box>
    </Container>
  );
};

export default ContractManufacturing;
