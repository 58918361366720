// // src/components/AboutUs.js

// import React from 'react';
// import { Container, Grid, Typography, Box } from '@mui/material';
// import './AboutUs.css';

// const AboutUs = () => {
//   return (
//     <Container maxWidth="lg" className="about-us">
//       <Box textAlign="center" my={4}>
//         <Typography variant="overline" display="block" gutterBottom>
//           ABOUT US
//         </Typography>
//         <Typography variant="h4" gutterBottom>
//           Educating and empowering the community
//         </Typography>
//       </Box>
//       <Grid container spacing={4}>
//         <Grid item xs={12} md={8}>
//           <Typography variant="h6" gutterBottom>
//             Perspiciatis unde omnis iste natus error inventore.
//           </Typography>
//           <Typography paragraph>
//             Perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
//           </Typography>
//           <Typography variant="h6" gutterBottom>
//             Dolor sit amet consectetur adipisicing elit. Unde, quasi.
//           </Typography>
//           <Typography paragraph>
//             Nemo dolores, iusto pariatur corporis quis ullam harum voluptate porro officiis aliquam quas explicabo?
//           </Typography>
//           <Typography paragraph>
//             Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias similique, consequuntur incidunt at repellendus ratione totam ex excepturi iste. Similique ipsum numquam recusandae, eligendi maiores temporibus officia harum labore deleniti quasi.
//           </Typography>
//         </Grid>
//         <Grid item xs={12} md={4} className="video-container">
//           <img src="/assets/images/video-placeholder.png" alt="Video Placeholder" className="video-thumbnail" />
//         </Grid>
//       </Grid>
//     </Container>
//   );
// };

// export default AboutUs;

import React from "react";
import { Box, Container, Typography, Paper, Grid } from "@mui/material";
import MissionIcon from '@mui/icons-material/Flag'; // Example icon for mission
import VisionIcon from '@mui/icons-material/Visibility'; // Example icon for vision
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

const AboutUs = () => {
  return (
    <Container maxWidth="lg" className="company-history">
      <Box sx={{ textAlign: "center", my: 4 }}>
        <Typography variant="h5" color="primary" gutterBottom>
          More About us.
        </Typography>
        {/* <Typography variant="h3" component="h1" gutterBottom>
          We’re the top rated agency.
        </Typography> */}
      </Box>
      {/* <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Typography variant="h6" color="secondary" gutterBottom>
            Our History & Goal.
          </Typography>
          <Typography variant="body1" paragraph>
            Our founders Dustin Moskovitz and Justin Rosenstein met while leading Engineering teams at Facebook. As operations scaled, they grew frustrated by how difficult it was to coordinate. <span style={{ color: '#ccc' }}>incidi ut labor et dolor magna aliu. enim ad mim venam, quis nostru labore lorem dus amet sample text.</span>
          </Typography>
        </Grid>
      </Grid> */}

      <Grid container spacing={4} className="history-section">
        <Grid textAlign="justify" item xs={12}>
          <Paper elevation={3} sx={{ padding: 4, backgroundColor: "#f9f9f9" }}>
            <Typography variant="h4" component="h1" gutterBottom>
              Our History
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              color="textSecondary"
              gutterBottom
            >
              SAR Pharma International, a beacon of pharmaceutical excellence,
              has a remarkable journey that began in 2010. Established with a
              mission to improve healthcare access and provide high-quality
              medicines, the company embarked on a path that would lead to
              global recognition and admiration.
            </Typography>
            <Grid container spacing={4} sx={{ marginTop: 2 }}>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" className="section-title">
                  A Humble Beginning
                </Typography>
                <Typography variant="body1" paragraph>
                  In its early days, SAR Pharma primarily focused on sourcing
                  and supplying a diverse range of medicines from Bangladesh.
                  This laid the foundation for a company dedicated to quality,
                  integrity, and patient care.
                </Typography>
                <Typography variant="h6" className="section-title">
                  A Response to Global Crisis
                </Typography>
                <Typography variant="body1" paragraph>
                  The world faced an unprecedented challenge with the outbreak
                  of the COVID-19 pandemic. SAR Pharma International swiftly
                  pivoted to meet the urgent global demand for emergency
                  medicines. With unwavering dedication, we supplied life-saving
                  medications to various corners of the world, contributing to
                  the global effort to combat the pandemic.
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" className="section-title">
                  A Commitment to Oncology
                </Typography>
                <Typography variant="body1" paragraph>
                  Today, SAR Pharma International is deeply committed to the
                  field of oncology medicine. We proudly source and supply a
                  broad spectrum of oncology medications, both from Bangladesh
                  and overseas sources. Our mission is to ensure that patients
                  worldwide have access to the critical medications they need to
                  combat cancer and improve their quality of life.
                </Typography>
                <Typography variant="h6" className="section-title">
                  Our Ongoing Journey
                </Typography>
                <Typography variant="body1" paragraph>
                  As we continue our journey, our focus remains steadfast: to
                  enhance healthcare, support patients in their battle against
                  disease, and contribute to the well-being of communities
                  around the globe. We invite you to explore our range of
                  oncology medicines and experience the commitment and
                  dedication that defines SAR Pharma International.
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={4} className="history-section">
        {/* <Grid item xs={12} md={12}>
          <Typography variant="h6" color="secondary" gutterBottom>
            About Us.
          </Typography>
          <Typography textAlign="justify" variant="body1" paragraph>
          SAR Pharma International is a renowned exporter of pharmaceutical items, specializing in the global distribution of medicines. With a strong emphasis on anti-cancer medicine, we have established ourselves as a trusted partner in the healthcare industry.
          </Typography>
        </Grid> */}
        <Grid textAlign="justify" item xs={12}>
          <Paper elevation={3} sx={{ padding: 4, backgroundColor: "#f9f9f9" }}>
            <Typography variant="h4" component="h1" gutterBottom>
              About Us
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              color="textSecondary"
              gutterBottom
            >
              SAR Pharma International is a renowned exporter of pharmaceutical
              items, specializing in the global distribution of medicines. With
              a strong emphasis on anti-cancer medicine, we have established
              ourselves as a trusted partner in the healthcare industry.
            </Typography>
            <Grid container spacing={4} sx={{ marginTop: 2 }}>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" paragraph>
                  We understand the critical importance of providing reliable
                  and high-quality medications to patients around the world. Our
                  dedicated team works tirelessly to ensure that the right
                  medicines reach those who need them, regardless of their
                  geographical location. We pride ourselves on our ability to
                  source and supply internationally branded medicines, catering
                  to the diverse needs of our clients from various parts of the
                  globe.
                </Typography>
                <Typography variant="body1" paragraph>
                  In addition to our expertise in oncology medicine, we also
                  offer a comprehensive range of surgical items, further
                  expanding our product portfolio to meet the demands of
                  healthcare professionals and institutions. By maintaining
                  strong relationships with reputable manufacturers and
                  suppliers, we guarantee the authenticity and efficacy of the
                  products we deliver.
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" paragraph>
                  Our commitment to customer satisfaction is unwavering. SAR
                  Pharma International is committed to providing direct access
                  to medicine for patients and customers, ensuring a streamlined
                  and efficient experience. With our exceptional shipping
                  methods, we guarantee swift and secure delivery, minimizing
                  any delays in receiving essential treatments. Our competitive
                  pricing ensures that quality medicine remains accessible and
                  affordable to all, while our secure payment methods provide
                  peace of mind.
                </Typography>
                <Typography variant="body1" paragraph>
                  At SAR Pharma International, we prioritize the well-being and
                  health of individuals worldwide. Through our extensive network
                  and dedication to excellence, we strive to make a positive
                  impact on global healthcare by supplying superior
                  pharmaceutical products and facilitating access to life-saving
                  treatments.
                </Typography>
              </Grid>
            </Grid>
            <Typography
              variant="h6"
              component="h3"
              sx={{ marginTop: 4, textAlign: "center", color: "primary.main" }}
            >
              Choose SAR Pharma International as your trusted partner, and
              together, let's make a difference in the lives of patients and
              communities across the globe.
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={4} className="mission-vision">
        <Grid textAlign="justify" item xs={12}>
          <Paper elevation={3} sx={{ padding: 4, backgroundColor: "#f9f9f9" }}>
            <Typography variant="h4" component="h1" gutterBottom>
              Our Mission Vision & Goal
            </Typography>
            {/* <Typography
              variant="h6"
              component="h2"
              color="textSecondary"
              gutterBottom
            >
              To provide high-quality products that combine performance with
              value pricing, while establishing a successful relationship with
              our customers and our suppliers.
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              color="textSecondary"
              gutterBottom
            >
              To constantly strive to meet and exceed our customer needs and
              expectations of price, service, and selection. To this end, we
              will perform periodic reviews of the marketplace to improve our
              offerings.
            </Typography> */}
            {/* <Grid container spacing={4} sx={{ marginTop: 2 }}>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" paragraph>
                  To be the leading provider in our industry, recognized for our
                  customer satisfaction, innovative solutions, and commitment to
                  quality.
                </Typography>
                <Typography variant="body1" paragraph>
                  To empower communities through sustainable practices and
                  support initiatives that enhance the overall well-being of our
                  society.
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" paragraph>
                  To be the leading provider in our industry, recognized for our
                  customer satisfaction, innovative solutions, and commitment to
                  quality.
                </Typography>
                <Typography variant="body1" paragraph>
                  To empower communities through sustainable practices and
                  support initiatives that enhance the overall well-being of our
                  society.
                </Typography>
              </Grid>
            </Grid> */}


            <Grid item xs={12} md={12}>
          <Box className="mission-vision-item">
            <MissionIcon className="mission-vision-icon" />
            <Box ml={2}>
              <Typography variant="h5" gutterBottom>
                Our Mission
              </Typography>
              <Typography paragraph>
                To provide high-quality products that combine performance with value pricing, while establishing a successful relationship with our customers and our suppliers.
              </Typography>
              <Typography paragraph>
                To constantly strive to meet and exceed our customer needs and expectations of price, service, and selection. To this end, we will perform periodic reviews of the marketplace to improve our offerings.
              </Typography>
            </Box>
          </Box>
          <Box className="mission-vision-item">
            <VisionIcon className="mission-vision-icon" />
            <Box ml={2}>
              <Typography variant="h5" gutterBottom>
                Our Vision
              </Typography>
              <Typography paragraph>
                To be the leading provider in our industry, recognized for our customer satisfaction, innovative solutions, and commitment to quality.
              </Typography>
              <Typography paragraph>
                To empower communities through sustainable practices and support initiatives that enhance the overall well-being of our society.
              </Typography>
            </Box>
          </Box>

          <Box className="mission-vision-item">
            <EmojiEventsIcon className="mission-vision-icon" />
            <Box ml={2}>
              <Typography variant="h5" gutterBottom>
                Our Goals
              </Typography>
              <Typography paragraph>
                To be the leading provider in our industry, recognized for our customer satisfaction, innovative solutions, and commitment to quality.
              </Typography>
              <Typography paragraph>
                To empower communities through sustainable practices and support initiatives that enhance the overall well-being of our society.
              </Typography>
            </Box>
          </Box>
        </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AboutUs;
