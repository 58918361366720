import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from "react-router-dom"
import MenuIcon from '@mui/icons-material/Menu';

const theme = createTheme({
  // Your theme customizations go here
});

function SubHeader() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static">
      <Box className="top-bar" bgcolor="#F3F4F6">
        <Container maxWidth="lg">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Box className="top-bar-left">
                {/* Responsive Menu */}
                <IconButton
                  size="large"
                  aria-controls="responsive-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="responsive-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}><Link to="/about" color="inherit">About Us</Link></MenuItem>
                  <MenuItem onClick={handleClose}><Link to="/contact-us" color="inherit">Contact Us</Link></MenuItem>
                  <MenuItem onClick={handleClose}><Link to="/how-it-works" color="inherit">How It Works</Link></MenuItem>
                  <MenuItem onClick={handleClose}><Link to="/account" color="inherit">My Account</Link></MenuItem>
                  <MenuItem onClick={handleClose}><Link to="/login" color="inherit">Login</Link></MenuItem>
                </Menu>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </AppBar>
  );
}

export default SubHeader;
