import React from 'react';
import { Container, Typography, Paper, Box, Avatar, Divider } from '@mui/material';
import './GenericBrand.css';

const GenericBrand = () => {
  return (
    <Container className="section" maxWidth="lg">
      <Typography variant="h4" align="center" gutterBottom>
        Generic Brand
      </Typography>
      <Paper className="paper">
        <Box padding={4}>
          <Typography variant="h6" gutterBottom align="center">
            SAR Pharma International takes pride in its commitment to providing a comprehensive range of pharmaceutical solutions, extending beyond international branded medicines.
          </Typography>
          <Divider className="divider" />
          <Typography paragraph align="center">
            Our dedication to global healthcare extends to the supply of high-quality generic branded medicines sourced from reputable manufacturers in Bangladesh and other countries. By offering a diverse portfolio of generic pharmaceuticals, including oncology/anti-cancer medications, we aim to enhance accessibility to affordable yet efficacious treatment options worldwide. Our quality assurance processes ensure that every product we supply adheres to the highest industry standards, fostering trust among our partners and reinforcing our reputation.
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default GenericBrand;
