import React, { useState } from 'react';
import './Header.css';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Grid,
  Box,
  Menu,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Divider,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link, useLocation } from "react-router-dom"
import MenuIcon from '@mui/icons-material/Menu';
import SearchBar from './SearchBar';


const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [categoriesAnchorEl, setCategoriesAnchorEl] = useState(null);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleCategoriesClick = (event) => {
    setCategoriesAnchorEl(event.currentTarget);
  };

  const handleCategoriesClose = () => {
    setCategoriesAnchorEl(null);
  };


  const menuItems = [
    { label: "Anti-Cancer Orals", link: "/about-us" },
    { label: "Anti-Cancer Parenteral", link: "/contact-us" },
    { label: "Biological Products", link: "/how-it-works" },
    { label: "Anti-Viral", link: "/account" },
    { label: "General Products", link: "/login" }
  ];

  const navLinks = [
    { label: 'Home', link: '/' },
    { label: 'About Us', link: '/about-us' },
    { label: 'Our Services', link: '/our-services' },
    { label: 'Brand Medicines', link: '/brand-medicines' },
    { label: 'How It Works', link: '/how-it-works' },
    { label: 'Prescription', link: '/prescriptions' },
    { label: 'Contact Us', link: '/contact-us' },
  ];


  const SearchBarWrapper = () => {
    const location = useLocation();
    return location.pathname === '/' ? <SearchBar /> : null;
  };

  return (
    <AppBar position="static" className="header" elevation={0}>
      <Box className="top-bar" bgcolor="#F3F4F6">
        <Container maxWidth="lg">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="body2" color={"black"} component="p">
                Need help? Call to <Link href="tel:+099949343">+099949343</Link>
              </Typography>
            </Grid>
            <Grid item>
              <Box className="top-bar-right">
                {navLinks.map((item, index) => (
                  <Link key={index} to={item.link} color="inherit" className="nav-link">
                    {item.label}
                  </Link>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container maxWidth="lg">
        <Toolbar className="main-header">
          <Box className="logo">
            <Link to="/" color="inherit"><img src="/assets/images/logo.png" alt="SarPharmaLogo" /></Link>
          </Box>
          <Box className="menu-section">
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
              className="menu-button"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={categoriesAnchorEl}
              open={Boolean(categoriesAnchorEl)}
              onClose={handleCategoriesClose}
              className="categories-menu"
            >
              {menuItems.map((item, index) => (
                <MenuItem key={index} onClick={handleCategoriesClose}>
                  <Link to={item.link} className="menu-link">{item.label}</Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
           <SearchBarWrapper />
           <Drawer className='drawer-main' anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
            <Box className="drawer-header">
              <IconButton onClick={handleDrawerToggle}>
                <MenuIcon />
              </IconButton>
            </Box>
            <Divider />
            <List>
              <ListItem button onClick={handleCategoriesClick}>
                <ListItemText primary="Categories" />
              </ListItem>
              <Divider />
              <Menu
                anchorEl={categoriesAnchorEl}
                open={Boolean(categoriesAnchorEl)}
                onClose={handleCategoriesClose}
                className="categories-menu"
              >
                {menuItems.map((item, index) => (
                  <MenuItem key={index} onClick={handleCategoriesClose}>
                    <Link to={item.link} className="menu-link">
                      {item.label}
                    </Link>
                  </MenuItem>
                ))}
              </Menu>
              <Divider />
              {navLinks.map((item, index) => (
                <ListItem button key={index} onClick={handleDrawerToggle}>
                  <Link to={item.link} className="drawer-link">
                    {item.label}
                  </Link>
                </ListItem>
              ))}
            </List>
          </Drawer>
        </Toolbar>
        
      </Container>
      
      
    </AppBar>
  );
};

export default Header;


