import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Box,
  Button,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Paper,
  Modal,
  IconButton,
} from "@mui/material";
import { CheckCircle, UploadFile, Close } from "@mui/icons-material";
import Login from "../auth/Login";
import Register from "../auth/Register";
import "./Prescription.css";

const Prescription = () => {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [error, setError] = useState("");

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (
      selectedFile &&
      ["image/jpeg", "image/png", "image/jpg"].includes(selectedFile.type)
    ) {
      setFile(selectedFile);
      setError("");
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setError("Please upload a valid image file (jpg, jpeg, png)");
      setFile(null);
      setPreview(null);
    }
  };

  const handleUpload = () => {
    if (!isLoggedIn()) {
      setIsLoginModalOpen(true);
    } else {
      alert("Uploading prescription...");
    }
  };

  const isLoggedIn = () => {
    return false;
  };

  const handleRegisterClick = () => {
    setIsLoginModalOpen(false);
    setIsRegisterModalOpen(true);
  };

  const handleLoginClick = () => {
    setIsRegisterModalOpen(false);
    setIsLoginModalOpen(true);
  };

  return (
    <div>
      <Container maxWidth="md">

      <Box my={4}>
          <Paper elevation={3} className="upload-section">
            <Typography variant="h5" gutterBottom>
              Upload Your Prescription
            </Typography>
            <input
              accept="image/jpeg, image/png, image/jpg"
              style={{ display: "none" }}
              id="raised-button-file"
              type="file"
              onChange={handleFileChange}
            />
            <label htmlFor="raised-button-file">
              <Button
                variant="contained"
                color="primary"
                component="span"
                startIcon={<UploadFile />}
              >
                Choose File
              </Button>
            </label>
            {error && (
              <Typography variant="body1" color="error" gutterBottom>
                {error}
              </Typography>
            )}
            {file && (
              <Box mt={2} textAlign="center">
                <img src={preview} alt="Preview" className="image-preview" />
                <Typography variant="body1" gutterBottom>
                  {file.name}
                </Typography>
              </Box>
            )}
            <Button
              variant="contained"
              color="secondary"
              onClick={handleUpload}
              sx={{ mt: 2 }}
            >
              Upload
            </Button>
          </Paper>
        </Box>
        <Box my={4}>
        <Paper  elevation={3} sx={{ padding: 4, backgroundColor: "#f9f9f9" }}>
          <Typography variant="h5" gutterBottom>
            Why Prescription is required ?
          </Typography>
          <Typography variant="p" gutterBottom>
            Prescription is necessary for direct patient supply
          </Typography>

          <Typography variant="p" gutterBottom>
            Prescription required to send medicine directly to patient. It will
            help a patient to access medicine quickly without any obstacles.
          </Typography>
          <Typography variant="p" gutterBottom>
            The prescription should have:
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <CheckCircle color="primary" />
              </ListItemIcon>
              <ListItemText primary="The treating doctor’s details" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircle color="primary" />
              </ListItemIcon>
              <ListItemText primary="The date on which the prescription was written" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircle color="primary" />
              </ListItemIcon>
              <ListItemText primary="The patient’s name and date of birth" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircle color="primary" />
              </ListItemIcon>
              <ListItemText primary="The name of medicine, its strength and quantity" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircle color="primary" />
              </ListItemIcon>
              <ListItemText primary="The doctor’s signature and official stamp" />
            </ListItem>
          </List>
          <Typography variant="body1" gutterBottom>
            You have to share your prescription during the placement of order
          </Typography>
        </Paper>

        </Box>
        
        
      </Container>
      <Modal open={isLoginModalOpen} onClose={() => setIsLoginModalOpen(false)}>
        <Box className="authModal">
          <Login
            onClose={() => setIsLoginModalOpen(false)}
            onRegisterClick={handleRegisterClick}
          />
        </Box>
      </Modal>
      <Modal
        open={isRegisterModalOpen}
        onClose={() => setIsRegisterModalOpen(false)}
      >
        <Box className="authModal">
          <Register
            onClose={() => setIsRegisterModalOpen(false)}
            onLoginClick={handleLoginClick}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default Prescription;
