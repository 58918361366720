import React from 'react';
import { Container, Typography, Paper, Box, Avatar, Divider } from '@mui/material';
import './InternationalBrand.css';

const InternationalBrand = () => {
  return (
    <Container className="section" maxWidth="lg">
      <Typography variant="h4" align="center" gutterBottom>
        International Brand
      </Typography>
      <Paper className="paper">
        <Box padding={4}>
          <Typography variant="h6" gutterBottom align="center">
            SAR Pharma International is a renowned exporter of internationally branded medicines, providing access to a wide range of high-quality pharmaceutical products for patients worldwide.
          </Typography>
          <Divider className="divider" />
          <Typography paragraph align="center">
            Through strong partnerships with reputable manufacturers and suppliers, we ensure the authenticity, safety, and efficacy of every medication we distribute. Our extensive catalog covers various therapeutic categories, including oncology/anti-cancer medicine, cardiovascular treatments, neurology medications, respiratory care, and more. With a reliable global supply chain, we offer a steady availability of internationally recognized medicines. Our knowledgeable team assists customers in selecting the most suitable options, and we strive to offer cost-effective pricing without compromising quality.
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default InternationalBrand;
