// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;


import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Product from './pages/Product';
// import Cart from './components/Cart';
// import Checkout from './components/Checkout';
// import Login from './pages/Login';
// import Signup from './pages/Signup';
import './App.css'; // Import global CSS
import { StoreProvider } from './context/Store';
import ContactUs from './components/Contact/ContactUs';
import HowItWorks from './components/HowItWorks/HowItWorks';
import NotFound from './components/404/NotFound';
import PrivacyPolicy from './components/PrivacyPolicy';
import SubHeader from './components/SubHeader/SubHeader';
import AboutUs from './components/about/AboutUs';
import MissionVision from './components/missionvision/MissionVision';
import OurServices from './components/services/OurServices';
import CompanyHistory from './components/companyhistory/CompanyHistory';
import BrandMedicineSupply from './components/services/BrandMedicineSupply';
import ContractManufacturing from './components/services/ContractManufacturing';
import SurgicalItemsSupply from './components/services/SurgicalItemsSupply';
import Prescription from './components/prescriptions/Prescription';
import BrandMedicine from './components/BrandMedicine/BrandMedicine';

const App = () => (
  
  <StoreProvider>
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/product/:id" element={<Product />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/mission-vision" element={<MissionVision />} />
        <Route path="/company-history" element={<CompanyHistory />} />
        <Route path="/our-services" element={<OurServices />} />
        <Route path="/prescriptions" element={<Prescription />} />
        <Route path="/brand-medicine-supply" element={<BrandMedicineSupply />} />
        <Route path="/brand-medicines" element={<BrandMedicine />} />
        <Route path="/contract-manufacturing" element={<ContractManufacturing />} />
        <Route path="/surgical-items-supply" element={<SurgicalItemsSupply />} />
        <Route path="*" element={<NotFound />} />

        {/* <Route path="/cart" component={Cart} />
        <Route path="/checkout" component={Checkout} />
        <Route path="/login" component={Login} />
        <Route path="/signup" component={Signup} /> */}
      </Routes>
      <Footer />
    </Router>
  </StoreProvider>
);

export default App;


